import { FC, useState } from 'react';

import { StyledLayout } from '../MeetingForm/Styled';
import { LoaderContainer } from '../LoadMeeting/Styled';
import Spinner from '../../components/icons/Spinner';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import ChangetPasswordForm from '../../components/ChangePasswordForm';
interface ForgotPasswordProps {
  children?: React.ReactNode;
}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [loading, setLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [isForgotPasswordFormVisible, setIsForgotPasswordFormVisible] = useState(true);
  const [error, setError] = useState('');

  const handleOnSuccessForgotPassword = (email: string) => {
    setInputEmail(email);
    setIsForgotPasswordFormVisible(false);
  };
  return (
    <>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoaderContainer>
            <Spinner size={3.5} />
            <div>Please wait...</div>
          </LoaderContainer>
        </div>
      )}
      <StyledLayout>
        {error ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '200px', textTransform: 'uppercase' }}>{error}</div>
        ) : isForgotPasswordFormVisible ? (
          <ForgotPasswordForm onSuccess={handleOnSuccessForgotPassword} />
        ) : (
          <ChangetPasswordForm inputEmail={inputEmail}></ChangetPasswordForm>
        )}
      </StyledLayout>
    </>
  );
};

export default ForgotPassword;
