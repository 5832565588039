import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';

import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { setLocalStorage } from '../../utils/common';
import { STORAGE } from '../../constants';
import { IProduct, IResponse } from '../../types';
import { SubscriptionPlans } from '../../constants/http';
import useAxios from '../../hooks/useAxios';
import { SelectOptions } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Select';

interface Props extends BaseSdkProps {
  children: React.ReactNode;
}

const UPProductContext = createContext<{
  productOptions: SelectOptions[] | undefined;
  setProductOptions: React.Dispatch<React.SetStateAction<SelectOptions[]>> | undefined;
  products: IProduct[] | undefined;
  setProducts: React.Dispatch<React.SetStateAction<IProduct[] | undefined>> | undefined;
}>({
  productOptions: undefined,
  products: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProductOptions: (productOptions) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProducts: (products) => {},
});
export const UPProductProvider: FC<Props> = ({ children }) => {
  const { fetch: getProducts } = useAxios<IResponse<IProduct[]>>();

  const [productOptions, setProductOptions] = useState<SelectOptions[]>([]);
  const [products, setProducts] = useState<IProduct[] | undefined>(undefined);
  async function getProductsAsync() {
    const getProductsResult = await getProducts({
      url: SubscriptionPlans.GET,
      method: 'GET',
      params: {
        isProgram: true,
        isDailyWorkout: true,
        isOnlinePhysioAppointment: true,
      },
    });
    if (getProductsResult && getProductsResult.isSuccess && getProductsResult.result && getProductsResult.result.length > 0) {
      initProducts(getProductsResult.result);
      setLocalStorage(STORAGE.COMMSPRODUCTS, getProductsResult.result);
    }
  }

  function initProducts(products: IProduct[]) {
    const options = products
      .sort((a: IProduct, b: IProduct) => (a.name < b.name ? -1 : 1))
      .map((product: IProduct) => {
        return {
          label: product.name,
          value: product.id,
        };
      });
    options.unshift({
      label: '--- Select ---',
      value: '',
    });
    setProductOptions(options);
    setProducts(products);
  }
  useEffect(() => {
    getProductsAsync();
  }, []);

  return <UPProductContext.Provider value={{ products, setProducts, productOptions, setProductOptions }}>{children}</UPProductContext.Provider>;
};

export const useUPProduct = () => useContext(UPProductContext);
