import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 20rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (min-width: 600px) and (min-height: 600px) {
    min-height: 35.75rem;
    max-width: 20rem;
    border-radius: 0.25rem;
  }
`;
