import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { UPProductProvider } from '../../providers/UPProductProvider/UPProductProvider';
import CommunicationTracks from '../CommunicationTracks';
import CommunicationDetails from '../CommunicationDetails';

interface CommunicationWrapperProps {}
export const CommunicationWrapper: FC<CommunicationWrapperProps> = (props) => {
  const location = useLocation();
  return location.pathname === '/communication-tracks' ? (
    <UPProductProvider>
      <CommunicationTracks />
    </UPProductProvider>
  ) : (
    <UPProductProvider>
      <CommunicationDetails />
    </UPProductProvider>
  );
};

export default CommunicationTracks;
