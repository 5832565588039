import { Alarm, CalendarToday, Home, Search, Storefront } from '@material-ui/icons';
import { Flex, Navbar, NavbarItem } from 'amazon-chime-sdk-component-library-react';
import { Auth } from 'aws-amplify';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LogoutTimer from '../../components/LogoutTimer';
import { ROLES } from '../../constants';
import { useAppState } from '../../providers/AppStateProvider';
import { clearPatientAppointmentStorage } from '../../utils/common';
import UPNavbarHeader from './UPNavbarHeader';

export interface BaseProps {
  children?: React.ReactChildren | React.ReactNode;
}

const Base: FC<BaseProps> = (props) => {
  const { refreshToken, sessionExpired, logout, currentUserInfo } = useAppState();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handler = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  useEffect(() => {
    if (sessionExpired) {
      if (location.pathname.includes('meeting')) {
        refreshToken();
      } else {
        setShowLogoutPrompt(true);
      }
    } else {
      setShowLogoutPrompt(false);
    }
  }, [sessionExpired]);

  const handleLogout = () => {
    logout();
  };
  const handlePatientHomeNavigation = () => {
    clearPatientAppointmentStorage();
    history.push('/');
  };
  return (
    <div className='wrapper' style={{ overflow: 'auto', height: 'auto' }}>
      <Navbar style={{ width: '100%', padding: '0px' }}>
        <UPNavbarHeader title='My Account' logout={() => handleLogout()} isDesktop={isDesktop} />
      </Navbar>
      {isDesktop && currentUserInfo?.role && currentUserInfo?.role === ROLES.PRACTITIONER && (
        <Navbar style={{ height: '100%', position: 'absolute' }}>
          <NavbarItem icon={<CalendarToday />} label='Appointments' onClick={() => history.push('/appointments')} />
          <NavbarItem icon={<Search />} label='Search Patients' onClick={() => history.push('/search-patients')} />
          {currentUserInfo?.isHealthCoach && <NavbarItem icon={<Alarm />} label='Communication Tracks' onClick={() => history.push('/communication-tracks')} />}
          <NavbarItem icon={<Storefront />} label='Your Business' onClick={() => history.push('/your-business')} />
        </Navbar>
      )}
      {isDesktop && currentUserInfo?.role && currentUserInfo?.role === ROLES.PATIENT && (
        <Navbar style={{ height: '100%', position: 'absolute', zIndex: 20 }}>
          <NavbarItem icon={<Home />} label='Home' showLabel={true} onClick={() => handlePatientHomeNavigation()} />
        </Navbar>
      )}
      <div style={{ overflow: 'auto' }}>{props.children}</div>
      <LogoutTimer isOpen={showLogoutPrompt} toggleOpen={setShowLogoutPrompt} />
    </div>
  );
};

export default Base;
