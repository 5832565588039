import { TableCell } from '@material-ui/core';
import styled from 'styled-components';
export const StyledLayout = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledTableCell = (props: any) => (
  <TableCell
    style={{
      color: '#FFFFFF',
      backgroundColor: 'black',
      cursor: 'pointer',
    }}
    colSpan={props.colSpan}
    align={props.align}
  >
    {props.children}
  </TableCell>
);
