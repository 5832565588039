import { LogLevel } from 'amazon-chime-sdk-js';

export const AMAZON_CHIME_VOICE_CONNECTOR_PHONE_NUMDER = '+17035550122';

export const VIDEO_INPUT = {
  NONE: 'None',
  BLUE: 'Blue',
  SMPTE: 'SMPTE Color Bars',
};

export const AUDIO_INPUT = {
  NONE: 'None',
  440: '440 Hz',
};

export const MAX_REMOTE_VIDEOS = 25;

export const AVAILABLE_AWS_REGIONS = {
  'us-east-1': 'United States (N. Virginia)',
  'af-south-1': 'Africa (Cape Town)',
  'ap-northeast-1': 'Japan (Tokyo)',
  'ap-northeast-2': 'Korea (Seoul)',
  'ap-south-1': 'India (Mumbai)',
  'ap-southeast-1': 'Singapore',
  'ap-southeast-2': 'Australia (Sydney)',
  'ca-central-1': 'Canada',
  'eu-central-1': 'Germany (Frankfurt)',
  'eu-north-1': 'Sweden (Stockholm)',
  'eu-south-1': 'Italy (Milan)',
  'eu-west-1': 'Ireland',
  'eu-west-2': 'United Kingdom (London)',
  'eu-west-3': 'France (Paris)',
  'sa-east-1': 'Brazil (São Paulo)',
  'us-east-2': 'United States (Ohio)',
  'us-west-1': 'United States (N. California)',
  'us-west-2': 'United States (Oregon)',
};

export const VIDEO_INPUT_QUALITY = {
  '360p': '360p (nHD) @ 15 fps (600 Kbps max)',
  '540p': '540p (qHD) @ 15 fps (1.4 Mbps max)',
  '720p': '720p (HD) @ 15 fps (1.4 Mbps max)',
};

export const SDK_LOG_LEVELS = {
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  off: LogLevel.OFF,
};

export const DATA_MESSAGE_LIFETIME_MS = 300000;
export const DATA_MESSAGE_TOPIC = 'ChimeComponentLibraryDataMessage';
export const videoUrl = [
  'https://media.universalpractice.com.au/Videos/virtual_waiting_room_1.mp4',
  'https://media.universalpractice.com.au/Videos/virtual_waiting_room_2.mp4',
];

export const STORAGE = {
  TREATMENT_NOTE_FORM_TEMPLATES: 'treatmentNoteFromTemplates',
  PATIENT_TREATMENT_NOTE_FORM_ID: 'patientTreatmentNoteFormId',
  SAVE_PATIENT_TREATMENTS: 'savePatientTreatmentNotes',
  APPOINTMENT_ID: 'appointmentId',
  PATIENT_ID: 'patientId',
  SEARCHED_PATIENT: 'selectedPatient',
  EXERCISES: 'exercises',
  SELECTED_EXERCISES: 'selectedExercises',
  SELECTED_EXERCISES_IDS: 'selectedExercisesIds',
  PATIENT_EXTERNAL_ID: 'patientExternalId',
  SESSION: 'session',
  PROGRAM_SUBSCRIPTION_ID: 'programSubscriptionId',
  PRESET_PROGRAMS: 'presetPrograms',
  USER_PROGRAM: 'userProgram',
  PRODUCTS: 'products',
  COMMSPRODUCTS: 'commsProducts',
  DIARY_START_DATE: 'diaryStartDate',
  DIARY_END_DATE: 'diaryEndDate',
  DIARY_VIEW: 'diaryView',
};

export const ROLES = {
  PRACTITIONER: 'practitioner',
  PATIENT: 'patient',
};

export const SUBSCRIPTION_TYPES = {
  PROGRAM: 'Program',
  ADD_ON: 'AddOn',
  PREMIUM: 'Premium',
};

export const SUBSCRIPTION_GROUPS = {
  VPA: 'vpa',
};

export const SUBSCRIPTION_TYPES_PHYSIO_ASSIGNED_PROGRAMS = [SUBSCRIPTION_TYPES.PROGRAM, SUBSCRIPTION_TYPES.ADD_ON];

export const displayFormatDate = 'DD-MM-YYYY';
export const displayFormatDateTime = 'DD-MM-YYYY HH:mm';
export const monthAbbreviatedNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const PATIENT_CUSTOM_FIELD_NAMES = {
  MEMBERSHIP_NUMBER: 'Membership Number',
  INSURER: 'Insurer',
  PRIVATE_HEALTH_INSURANCE_NAME: 'Private Health Insurance Name',
};

export const TREATMENT_NOTE_DROPDOWN_FIELD_NAMES = ['Primary Joint', 'Secondary Joint', 'Pain', 'Wellbeing Score'];

export const TREATMENT_NOTE_FIELD_TYPES = {
  RADIO_BUTTONS: 'radiobuttons',
};
