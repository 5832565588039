import React, { FC } from 'react';
import styled from 'styled-components';

import classes from './UPForm.module.css';
import { UPHelper } from './UPHelper';

interface IFormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  heading?: string;
  required?: boolean;
  errorText?: string;
  error?: boolean;
}

const StyledFormGroup = styled.div<IFormGroupProps>`
  margin: 25px 5px;
  text-align: left;
`;

export const FormGroup: FC<IFormGroupProps> = ({ children, heading, required, errorText, error }) => {
  return (
    <StyledFormGroup>
      {heading && (
        <h5 className={`${classes.upform__questions} up-form-group-heading`}>
          {heading} {required && <span style={{ marginLeft: '5px' }}>*</span>}
        </h5>
      )}
      {children}
      <UPHelper active={!!error}>{errorText || 'Please fill out'}</UPHelper>
    </StyledFormGroup>
  );
};
