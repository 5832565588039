import { Typography } from '@material-ui/core';
import { Flex, useMeetingManager, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { displayFormatDateTime } from '../../constants';
import { TreatmentNote } from '../../constants/http';
import useAxios from '../../hooks/useAxios';
import { useAppState } from '../../providers/AppStateProvider';
import { useUPTreatmentNotePreview } from '../../providers/UPTreatmentNotePreviewProvider/UPTreatmentNotePreviewProvider';
import { IPatientTreatmentNote, Obj } from '../../types';
import UPFormSection, { UPFormSectionDisplayModes } from '../UPFormComponents/UPFormSection';
import UPNonModalDialog from '../UPNonModalDialog';
interface IUPTreatmentNotePreviewProps {
  children?: React.ReactChildren | React.ReactNode | string;
}
const UPTreatmentNotePreview: FC<IUPTreatmentNotePreviewProps> = (props) => {
  const { treatmentNoteId, setTreatmentNoteId } = useUPTreatmentNotePreview();
  const { currentUserInfo } = useAppState();
  const [patientTreatmentNote, setPatientTreatmentNote] = useState<IPatientTreatmentNote | null>();
  const { fetch: getPatientTreatmentNote, loading: getPatientTreatmentNoteLoading, error: getPatientTreatmentNoteError } = useAxios<IPatientTreatmentNote>();

  function handleOnCloseForm(): void {
    setTreatmentNoteId('');
    setPatientTreatmentNote(null);
  }
  useEffect(() => {
    if (treatmentNoteId) {
      setPatientTreatmentNote(null);
      initTreatmentNote();
    }
  }, [treatmentNoteId]);

  useEffect(() => {
    if (!currentUserInfo) handleOnCloseForm();
  }, [currentUserInfo]);

  const initTreatmentNote = async () => {
    if (treatmentNoteId) {
      const url = TreatmentNote.GET_BY_ID.replace('{id}', treatmentNoteId);
      const getResult = (await getPatientTreatmentNote({
        url: url,
        method: 'GET',
      })) as IPatientTreatmentNote;
      if (getResult) {
        setPatientTreatmentNote(getResult);
      }
    }
  };
  function handleFormChange(formData: Obj, index: number): void {
    // do nothing
  }
  return (
    <UPNonModalDialog
      showDialog={treatmentNoteId ? true : false}
      title={
        patientTreatmentNote ? patientTreatmentNote.title.replace('_', ' ') + ' ' + moment(patientTreatmentNote.created_at).format(displayFormatDateTime) : ''
      }
      onCloseForm={() => handleOnCloseForm()}
    >
      {patientTreatmentNote && patientTreatmentNote.content && patientTreatmentNote.content.sections?.length > 0 && (
        <Flex style={{ width: '100%' }}>
          <Flex style={{ textAlign: 'right' }}>
            {patientTreatmentNote && <Typography variant='body2'>Practitioner: {patientTreatmentNote.author_name}</Typography>}
          </Flex>

          {patientTreatmentNote &&
            patientTreatmentNote.content &&
            patientTreatmentNote.content.sections.map((s, index) => {
              return (
                <>
                  <UPFormSection
                    key={index}
                    questions={s.questions}
                    onChange={(formData, index) => handleFormChange(formData, index)}
                    sectionIndex={index}
                    displayMode={UPFormSectionDisplayModes.Condensed}
                  />
                </>
              );
            })}
        </Flex>
      )}
    </UPNonModalDialog>
  );
};
export default UPTreatmentNotePreview;
