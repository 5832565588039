import React, { useContext, useState, ReactNode, useEffect } from 'react';
import { VideoPriorityBasedPolicy } from 'amazon-chime-sdk-js';
import {
  MeetingMode,
  Layout,
  VideoFiltersCpuUtilization,
  AuthStatus,
  IDName,
  Obj,
  EventMessage,
  IUser,
  ISubscription,
  IPatientInfo,
  IColourMap,
  CommissionTypes,
} from '../types';
import { DEFAULT_REGION, getCurrentUser, JoinMeetingInfo } from '../utils/api';
import { useLogger } from 'amazon-chime-sdk-component-library-react';
import { Auth } from 'aws-amplify';
import { clone, getLocalStorage, removeLocalStorage, setLocalStorage } from '../utils/common';
import { ROLES, STORAGE } from '../constants';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useIntercom } from 'react-use-intercom';
import { getRandomLightColourHsl } from '../utils/style';

type Props = {
  children: ReactNode;
};

interface AppStateValue {
  meetingId: string;
  localUserName: string;
  region: string;
  isWebAudioEnabled: boolean;
  videoTransformCpuUtilization: string;
  imageBlob: Blob | undefined;
  isEchoReductionEnabled: boolean;
  meetingMode: MeetingMode;
  enableSimulcast: boolean;
  priorityBasedPolicy: VideoPriorityBasedPolicy | undefined;
  keepLastFrameWhenPaused: boolean;
  layout: Layout;
  joinInfo: JoinMeetingInfo | undefined;
  authStatus?: AuthStatus;
  activeExcercise: EventMessage | undefined;
  currentUserInfo: IUser | undefined;
  treatmentNotes?: Record<string, IDName<Obj>>;
  activeSubscriptions: ISubscription[] | undefined;
  currentPatientInfo: IPatientInfo | undefined;
  sessionExpired: boolean;
  commissionTypeColourMap: IColourMap;
  toggleWebAudio: () => void;
  toggleSimulcast: () => void;
  togglePriorityBasedPolicy: () => void;
  toggleKeepLastFrameWhenPaused: () => void;
  refreshToken: () => void;
  logout: () => void;
  handleUserSession: (userSession: CognitoUserSession) => void;
  setCpuUtilization: (videoTransformCpuUtilization: string) => void;
  toggleEchoReduction: () => void;
  setMeetingMode: React.Dispatch<React.SetStateAction<MeetingMode>>;
  setJoinInfo: (joinInfo: JoinMeetingInfo | undefined) => void;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  setMeetingId: React.Dispatch<React.SetStateAction<string>>;
  setLocalUserName: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setBlob: (imageBlob: Blob) => void;
  setAuthStatus: (auth?: AuthStatus) => void;
  setTreatmentNoteForm: (formData?: Record<string, IDName<Obj>>) => void;
  setActiveExcercise: React.Dispatch<React.SetStateAction<EventMessage | undefined>>;
  setCurrentUserInfo: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setActiveSubscriptions: React.Dispatch<React.SetStateAction<ISubscription[] | undefined>>;
  setCurrentPatientInfo: React.Dispatch<React.SetStateAction<IPatientInfo | undefined>>;
}

export const AppStateContext = React.createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return state;
}

const query = new URLSearchParams(location.search);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function AppStateProvider({ children }: Props) {
  const logger = useLogger();
  const intercom = useIntercom();

  const [meetingId, setMeetingId] = useState(query.get('meetingId') || '');
  const [region, setRegion] = useState(query.get('region') || DEFAULT_REGION);
  // @TODO: Need to dive deep in to the meeting modes. UP may required this.
  const [meetingMode, setMeetingMode] = useState(MeetingMode.Attendee);
  const [joinInfo, setJoinInfo] = useState<JoinMeetingInfo | undefined>(undefined);
  const [layout, setLayout] = useState(Layout.Gallery);
  const [localUserName, setLocalUserName] = useState('');
  const [isWebAudioEnabled, setIsWebAudioEnabled] = useState(true);
  const [priorityBasedPolicy, setPriorityBasedPolicy] = useState<VideoPriorityBasedPolicy | undefined>(undefined);
  const [enableSimulcast, setEnableSimulcast] = useState(false);
  const [keepLastFrameWhenPaused, setKeepLastFrameWhenPaused] = useState(false);
  const [isEchoReductionEnabled, setIsEchoReductionEnabled] = useState(false);
  const [videoTransformCpuUtilization, setCpuPercentage] = useState(VideoFiltersCpuUtilization.Disabled);
  const [imageBlob, setImageBlob] = useState<Blob | undefined>(undefined);
  const [auth, setAuth] = useState<AuthStatus | undefined>();
  const [treatmentNotes, setTreatmentNotes] = useState<Record<string, IDName<Obj>>>();
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [activeExcercise, setActiveExcercise] = useState<EventMessage | undefined>();
  const [currentUserInfo, setCurrentUserInfo] = useState<IUser | undefined>(undefined);
  const [activeSubscriptions, setActiveSubscriptions] = useState<ISubscription[] | undefined>();
  const [currentPatientInfo, setCurrentPatientInfo] = useState<IPatientInfo | undefined>();
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null);
  const commissionTypeColourMap = {};
  commissionTypeColourMap[CommissionTypes.Referral] = getRandomLightColourHsl();
  commissionTypeColourMap[CommissionTypes.Appointment] = getRandomLightColourHsl();
  commissionTypeColourMap[CommissionTypes.Trailing] = getRandomLightColourHsl();

  useEffect(() => {
    async function loadImage() {
      const canvas = document.createElement('canvas');
      canvas.width = 500;
      canvas.height = 500;
      const ctx = canvas.getContext('2d');
      if (ctx !== null) {
        const grd = ctx.createLinearGradient(0, 0, 250, 0);
        grd.addColorStop(0, '#000428');
        grd.addColorStop(1, '#004e92');
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 500, 500);
        canvas.toBlob(function (blob) {
          if (blob !== null) {
            setImageBlob(blob);
          }
        });
      }
    }

    try {
      Auth.currentSession()
        .then(async (auth) => {
          if (!auth) {
            return;
          }

          handleUserSession(auth);
        })
        .catch(() => setAuthStatus());
    } catch (err) {
      setAuthStatus();
      console.log(err);
    }

    loadImage();

    const form = getLocalStorage<Record<string, IDName<Obj>>>(STORAGE.SAVE_PATIENT_TREATMENTS);
    setTreatmentNotes(form || {});
  }, []);

  useEffect(() => {
    if (!sessionExpired && timeoutHandler) {
      clearTimeout(timeoutHandler);
      setTimeoutHandler(null);
    }
  }, [sessionExpired]);

  const toggleWebAudio = (): void => {
    setIsWebAudioEnabled((current) => !current);
  };

  const toggleSimulcast = (): void => {
    setEnableSimulcast((current) => !current);
  };

  const togglePriorityBasedPolicy = (): void => {
    if (priorityBasedPolicy) {
      setPriorityBasedPolicy(undefined);
    } else {
      setPriorityBasedPolicy(new VideoPriorityBasedPolicy(logger));
    }
  };

  const refreshToken = (): void => {
    setSessionExpired(false);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const currentSession = currentUser.signInUserSession;
        console.log('Refreshing user session');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        currentUser.refreshSession(currentSession.refreshToken, (err: any, session: CognitoUserSession) => {
          if (err) {
            console.error(err);
          } else if (session) {
            console.log('Refreshing user session - Successful');
            handleUserSession(session);
          }
        });
      })
      .catch((e) => console.error(e));
  };

  const handleUserSession = (auth: CognitoUserSession) => {
    const data = clone(auth);
    setAuthStatus(data);
    setSessionExpired(false);
    const accessToken = auth?.getAccessToken();
    if (accessToken) {
      const token = accessToken?.getJwtToken();
      let refresh = accessToken.getExpiration() * 1000 - Date.now();
      refresh = refresh - 1 * 60 * 1000; // refresh 1 mins before timeout
      if (refresh < 0) refresh = 0;

      const refreshDate = new Date(Date.now() + refresh);
      console.log(`Token expires is at ${refreshDate.toLocaleTimeString()}`);
      const handler = setTimeout(() => {
        setSessionExpired(true);
      }, refresh);

      setTimeoutHandler(handler);
      getCurrentUser(token).then((user) => {
        if (!user) {
          return;
        }
        setCurrentUserInfo(user);
        if (user.role === ROLES.PATIENT) {
          setLocalStorage(STORAGE.PATIENT_ID, user.crmPatientId);
        }
      });
    }
  };

  const logout = (): void => {
    if (intercom) intercom.shutdown();
    localStorage.clear();
    sessionStorage.clear();
    setAuthStatus();
    setSessionExpired(false);

    setTreatmentNotes(undefined);
    setActiveExcercise(undefined);
    setCurrentUserInfo(undefined);
    setActiveSubscriptions(undefined);
    setCurrentPatientInfo(undefined);
    //window.location.href = '/'; //clear the state
  };

  const toggleKeepLastFrameWhenPaused = (): void => {
    setKeepLastFrameWhenPaused((current) => !current);
  };

  const setCpuUtilization = (filterValue: string): void => {
    setCpuPercentage(filterValue);
  };

  const setBlob = (imageBlob: Blob): void => {
    setImageBlob(imageBlob);
  };

  const toggleEchoReduction = (): void => {
    setIsEchoReductionEnabled((current) => !current);
  };

  const setAuthStatus = (auth?: AuthStatus): void => {
    if (!auth) {
      localStorage.clear();
      setAuth(auth);
      return;
    }

    setAuth(auth);
  };

  const setTreatmentNoteForm = (formData?: Record<string, IDName<Obj>>): void => {
    if (!formData) {
      setTreatmentNotes(() => {
        removeLocalStorage(STORAGE.SAVE_PATIENT_TREATMENTS);
        return undefined;
      });
    }

    setTreatmentNotes((forms) => {
      const updated = { ...forms, ...formData };
      setLocalStorage(STORAGE.SAVE_PATIENT_TREATMENTS, updated);
      return updated;
    });
  };

  const providerValue = {
    meetingId,
    localUserName,
    isWebAudioEnabled,
    videoTransformCpuUtilization,
    imageBlob,
    isEchoReductionEnabled,
    region,
    meetingMode,
    layout,
    joinInfo,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    activeExcercise,
    authStatus: auth,
    currentUserInfo,
    treatmentNotes,
    activeSubscriptions,
    currentPatientInfo,
    sessionExpired,
    refreshToken,
    handleUserSession,
    logout,
    toggleWebAudio,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    toggleSimulcast,
    setCpuUtilization,
    toggleEchoReduction,
    commissionTypeColourMap,
    setMeetingMode,
    setLayout,
    setJoinInfo,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setBlob,
    setAuthStatus,
    setTreatmentNoteForm,
    setActiveExcercise,
    setCurrentUserInfo,
    setActiveSubscriptions,
    setCurrentPatientInfo,
  };

  return <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>;
}
