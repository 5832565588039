import { Dialog, Fade, Paper, withStyles } from '@material-ui/core';
import { CheckBoxOutlineBlank, Close, Minimize } from '@material-ui/icons';
import { Flex, IconButton } from 'amazon-chime-sdk-component-library-react';
import { FC, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
interface IUPNonModalDialogProps {
  children?: React.ReactChildren | React.ReactNode | string;
  showDialog: boolean;
  title: string;
  onCloseForm: () => void;
}
const UPNonModalDialog: FC<IUPNonModalDialogProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const UPNonModalDialogWindowStates = {
    Minimize: 'Minimize',
    Maximize: 'Maximize',
  };
  const [windowState, setWindowState] = useState<string>(UPNonModalDialogWindowStates.Maximize);

  useEffect(() => {
    setOpen(props.showDialog);
  }, [props.showDialog]);

  const handleCloseButton = () => {
    setOpen(false);
    props.onCloseForm();
  };

  return (
    <Draggable>
      <Paper
        aria-modal='false'
        aria-label='Cookie banner'
        square
        variant='outlined'
        tabIndex={-1}
        style={{
          position: 'absolute',
          top: 100,
          left: 400,
          pointerEvents: 'auto',
          backgroundColor: 'rgb(27, 28, 32)',
          color: '#000000',
          overflow: 'hidden',
          border: '1px solid #FFFFFF',
          display: open ? 'block' : 'none',
          width: 550,
          zIndex: 2000,
        }}
      >
        <Flex style={{ height: windowState === UPNonModalDialogWindowStates.Maximize ? '70vh' : 'auto', color: '#FFFFFF' }}>
          <Flex justifyContent='space-between' style={{ display: 'flex', padding: 12, cursor: 'pointer' }}>
            <Flex style={{ display: 'flex' }} alignItems='center'>
              {props.title}
            </Flex>
            <Flex>
              <IconButton icon={<CheckBoxOutlineBlank />} label='Maximize' onClick={() => setWindowState(UPNonModalDialogWindowStates.Maximize)} />
              <IconButton icon={<Minimize />} label='Minimize' onClick={() => setWindowState(UPNonModalDialogWindowStates.Minimize)} />
              <IconButton icon={<Close />} label='Close' onClick={() => handleCloseButton()} />
            </Flex>
          </Flex>
          <Flex
            style={{
              overflow: 'auto',
              height: windowState === UPNonModalDialogWindowStates.Maximize ? '90%' : 'auto',
              display: windowState === UPNonModalDialogWindowStates.Minimize ? 'none' : 'block',
              padding: 12,
            }}
          >
            {props.children}
          </Flex>
        </Flex>
      </Paper>
    </Draggable>
  );
};

export default UPNonModalDialog;
