import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';

import { CRMFormTypes } from '../../constants/up-form';
import UPSwitch from './UPSwitch';
import { clone } from '../../utils/common';
import { Obj } from '../../types';
import { FormGroup } from './FormGroup';
import DatePicker from './UPDatePicker';
import UPRadio from './UPRadio';
import UPTextArea from './UPTextArea';
import UPTextInput from './UPTextInput';
import classes from './UPForm.module.css';
import UPDropDown from './UPDropDown';
import { TREATMENT_NOTE_DROPDOWN_FIELD_NAMES, TREATMENT_NOTE_FIELD_TYPES } from '../../constants';

interface IUPFormSectionProps {
  questions: Obj[];
  onChange: (data: Obj, sectionIndex: number) => void;
  sectionIndex: number;
  displayMode: string;
}

export const UPFormSectionDisplayModes = {
  Condensed: 'Condensed',
  FixedHeight: 'FixedHeight',
};

const getMaxWidth = (answers: Obj[], elemWidth = 48, minWidth = 170) => {
  const defaultValue = minWidth + 'px';
  try {
    const width = Math.max(...answers.map((el) => el.value.length)) * 12 + elemWidth;
    return width < minWidth ? defaultValue : width > 375 ? 375 : width;
  } catch (err) {
    return defaultValue;
  }
};

const UPFormSection = ({ questions, onChange, sectionIndex, displayMode }: IUPFormSectionProps) => {
  const handleFormChange = (
    mainIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    result?: boolean | string,
    subIndex?: number,
    reset?: boolean,
    other?: boolean,
    setValue?: (value: string) => void
  ) => {
    const data = clone(questions);

    if (other && data[mainIndex]?.other?.enabled) {
      data[mainIndex].other.selected = result;
      onChange(data, sectionIndex);
      return;
    }

    if (typeof subIndex === 'number' && data[mainIndex]?.answers?.[subIndex] && !reset) {
      const question = data[mainIndex];
      question.answers[subIndex] = { ...data[mainIndex].answers[subIndex], selected: result ? result : undefined };
      onChange(data, sectionIndex);
      return;
    }

    if (typeof subIndex === 'number' && data[mainIndex]?.answers?.[subIndex] && reset) {
      data[mainIndex].answers = data[mainIndex].answers.map((res: Obj) => ({ ...res, selected: undefined }));
      data[mainIndex].answers[subIndex] = { ...data[mainIndex].answers[subIndex], selected: result ? result : undefined };
      onChange(data, sectionIndex);
      return;
    }

    if (reset && !result) {
      //reset to no selection
      data[mainIndex].answers = data[mainIndex].answers.map((res: Obj) => ({ ...res, selected: undefined }));
      onChange(data, sectionIndex);
      return;
    }

    data[mainIndex].answer = event.target?.value;
    if (setValue) {
      setValue(event.target?.value);
    }
    onChange(data, sectionIndex);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CheckBox = (field: Obj, key: string, index: number, answers: any) => {
    const width = getMaxWidth(field.answers);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const switches = answers.map((p: Obj, i: number) => (
      <div key={`switch-${key}-${p.value}-${i}`}>
        <UPSwitch
          wrapperStyles={{ padding: '1rem', width, lineHeight: '20px' }}
          checked={!!p.selected}
          onChange={(e) => handleFormChange(index, e, e.target.checked, i)}
        >
          {p.value}
        </UPSwitch>
      </div>
    ));

    if (field?.other?.enabled) {
      let addOther = true;
      if (displayMode === UPFormSectionDisplayModes.Condensed) {
        addOther = field?.other?.selected;
      }
      if (addOther)
        switches.push(
          <div key={`switch-${key}-other`}>
            <UPSwitch
              wrapperStyles={{ padding: '1rem', width }}
              checked={!!field?.other?.selected}
              onChange={(e) => handleFormChange(index, e, e.target.checked, undefined, undefined, true)}
            >
              Other
            </UPSwitch>
          </div>
        );
    }

    return switches;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const RadioButton = (field: Obj, key: string, index: number, answers: any) => {
    const width = getMaxWidth(field.answers, 20, 120);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return answers.map((p: Obj, i: number) => (
      <div key={`${key}-${p.value}-${i}`} style={{ width, padding: '1rem' }}>
        <UPRadio
          value={p.value}
          label={p.value}
          checked={!!p.selected}
          onChange={(e) => handleFormChange(index, e, e.target.checked, i, true)}
          radioProps={{ name: field.name }}
        />
      </div>
    ));
  };

  const DropDown = (field: Obj, key: string, index: number, answers: any) => {
    const getFieldValue = () => {
      if (field.type === TREATMENT_NOTE_FIELD_TYPES.RADIO_BUTTONS) {
        const selected = field.answers.find((a: any) => a.selected);
        if (selected) return selected.value;
      } else return field.answer;
    };
    const handleChange = function (e: React.ChangeEvent<HTMLSelectElement>): void {
      const value = e?.target?.value;

      if (value) {
        if (field.type === TREATMENT_NOTE_FIELD_TYPES.RADIO_BUTTONS) {
          for (let i = 0; i < field.answers.length; i++) {
            const ans = field.answers[i];
            if (ans.value === value) {
              handleFormChange(index, e, true, i, true);
            }
          }
        }
      } else handleFormChange(index, e, undefined, undefined, true);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
      <FormGroup key={`${key}-${field.name}`} heading={''} required={field.required} error={field.invalid}>
        <UPDropDown
          fieldName={field.name}
          fieldValue={getFieldValue()}
          index={0}
          onChange={handleChange}
          readOnly={displayMode === UPFormSectionDisplayModes.Condensed}
          options={field.answers}
        />
      </FormGroup>
    );
  };

  return (
    <div style={{ minHeight: displayMode === UPFormSectionDisplayModes.FixedHeight ? 'auto' : 'auto' }}>
      {(questions || []).map((field: Obj, index: number) => {
        const key = `${field.type}-${index}-${sectionIndex}}`;
        if (TREATMENT_NOTE_DROPDOWN_FIELD_NAMES.includes(field.name)) {
          const answers = field.answers;
          return DropDown(field, key, index, answers);
        }
        switch (field.type) {
          case CRMFormTypes.CHECKBOXES: {
            let answers = field.answers;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (displayMode === UPFormSectionDisplayModes.Condensed) answers = answers.filter((a: any) => a.selected);
            if (!answers || answers.length == 0) return '';
            else
              return (
                <FormGroup key={`${key}-${field.name}`} heading={field.name} required={field.required} error={field.invalid}>
                  <div className={classes.form__answer__container}>{CheckBox(field, key, index, answers)}</div>
                </FormGroup>
              );
          }
          case CRMFormTypes.RADIOBUTTONS: {
            let answers = field.answers;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (displayMode === UPFormSectionDisplayModes.Condensed) answers = answers.filter((a: any) => a.selected);
            if (!answers || answers.length == 0) return '';
            else
              return (
                <FormGroup key={`${key}-${field.name}`} heading={field.name} required={field.required} error={field.invalid}>
                  <div style={{ color: 'white', justifyContent: 'left' }} className={classes.form__answer__container}>
                    {RadioButton(field, key, index, answers)}
                  </div>
                </FormGroup>
              );
          }

          case CRMFormTypes.TEXT: {
            if (displayMode === UPFormSectionDisplayModes.Condensed && !field.answer) return '';
            else
              return (
                <FormGroup key={`${key}-${field.name}`} heading={field.name} required={field.required} error={field.invalid}>
                  <UPTextInput
                    className={`up-input ${classes.form__answer__input}`}
                    fieldName={field.name}
                    fieldValue={field.answer}
                    index={index}
                    onChange={handleFormChange}
                    readOnly={displayMode === UPFormSectionDisplayModes.Condensed ? true : false}
                  />
                  {/* <Input
                  className={`up-input ${classes.form__answer__input}`}
                  id={field.name}
                  value={field.answer || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(index, e)}
                /> */}
                </FormGroup>
              );
          }
          case CRMFormTypes.PARAGRAPH: {
            if (displayMode === UPFormSectionDisplayModes.Condensed && !field.answer) return '';
            else
              return (
                <FormGroup key={`${key}-${field.name}`} heading={field.name} required={field.required} error={field.invalid}>
                  {/* Problem with cursor moving to end <Textarea
                  className={`up-input ${classes.form__answer__input}`}
                  id={field.name}
                  value={field.answer || ''}
                  label=''
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(index, e)}
                /> */}
                  <UPTextArea
                    className={`up-input ${classes.form__answer__input}`}
                    fieldName={field.name}
                    fieldValue={field.answer}
                    index={index}
                    onChange={handleFormChange}
                    readOnly={displayMode === UPFormSectionDisplayModes.Condensed ? true : false}
                  />
                </FormGroup>
              );
          }
          case CRMFormTypes.DATE: {
            if (displayMode === UPFormSectionDisplayModes.Condensed && !field.answer) return '';
            else
              return (
                <FormGroup key={`${key}-${field.name}`} heading={field.name} required={field.required} error={field.invalid}>
                  <DatePicker
                    id={uuid()}
                    onChange={(e) => handleFormChange(index, e as React.ChangeEvent<HTMLInputElement>)}
                    date={field.answer}
                    displayFormat='DD-MM-YYYY'
                    outputFormat='YYYY-MM-DD'
                    isOutsideRange={() => false}
                  />
                </FormGroup>
              );
          }
          default:
            return (
              <Fragment key={`${key}-${field.name}`}>
                {field?.required ? (
                  <FormGroup heading={field.name} required={field.required} style={{ color: '#f44336' }}>
                    <label>{`Input type ${field.type} is not supported. Please remove this from CRM.`}</label>
                  </FormGroup>
                ) : null}
              </Fragment>
            );
        }
      })}
    </div>
  );
};

export default UPFormSection;
