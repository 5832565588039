import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';

import React, { createContext, FC, useContext, useState } from 'react';

interface Props extends BaseSdkProps {
  children: React.ReactNode;
}

const UPTreatmentNotePreviewContext = createContext<{
  showTreatmentNote: boolean;
  setShowTreatmentNote: React.Dispatch<React.SetStateAction<boolean>>;
  treatmentNoteId: string;
  setTreatmentNoteId: React.Dispatch<React.SetStateAction<string>>;
  setTreatmentNoteToShow: (treatmentNoteId: string) => void;
}>({
  showTreatmentNote: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowTreatmentNote: () => {},
  treatmentNoteId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTreatmentNoteId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTreatmentNoteToShow: () => {},
});

export const UPTreatmentNotePreviewProvider: FC<Props> = ({ children }) => {
  const [showTreatmentNote, setShowTreatmentNote] = useState<boolean>(false);
  const [treatmentNoteId, setTreatmentNoteId] = useState<string>('');
  const setTreatmentNoteToShow = (treatmentNoteId: string) => {
    setTreatmentNoteId(treatmentNoteId);
    if (treatmentNoteId) setShowTreatmentNote(true);
    else setShowTreatmentNote(false);
  };

  return (
    <UPTreatmentNotePreviewContext.Provider value={{ showTreatmentNote, setShowTreatmentNote, treatmentNoteId, setTreatmentNoteId, setTreatmentNoteToShow }}>
      {children}
    </UPTreatmentNotePreviewContext.Provider>
  );
};

export const useUPTreatmentNotePreview = () => useContext(UPTreatmentNotePreviewContext);
