/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAppState } from '../providers/AppStateProvider';

export interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { authStatus } = useAppState();

  return (
    <Route {...rest} render={(props) => (authStatus ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)} />
  );
};

export default PrivateRoute;
