import React, { ChangeEvent, FC, useState } from 'react';
import { FormField, Heading, Input } from 'amazon-chime-sdk-component-library-react';
import { StyledWrapper } from './Styled';
import logo from '../../assets/Universal_Practice_CMYK_White-300-100.png';
import UPButton from '../../components/UPButton';
import { EmailRegex } from '../../utils/common';
import { Auth } from 'aws-amplify';
import { useAppState } from '../../providers/AppStateProvider';

interface ChangePasswordFormProps {
  children?: React.ReactNode;
  inputEmail: string;
}

const ChangetPasswordForm: FC<ChangePasswordFormProps> = ({ inputEmail }) => {
  const [email, setEmail] = useState(inputEmail);
  const [emailError, setEmailError] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const appState = useAppState();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValidEmail = email.trim();
    const isValidPassword = password.trim();
    const isValidConfirmPassword = confirmPassword.trim();
    const isValidCode = code.trim();
    if (!isValidEmail || !isValidPassword || !isValidConfirmPassword || !isValidCode) {
      setEmailError(!isValidEmail);
      setPasswordError(!isValidPassword);
      setConfirmPasswordErrorText('Please confirm new password');
      setConfirmPasswordError(!isValidConfirmPassword);
      setCodeError(!isValidCode);
      return;
    }
    if (isValidEmail && !EmailRegex.test(isValidEmail)) {
      setEmailError(!isValidEmail);
    }
    if (isValidConfirmPassword && isValidPassword) {
      if (password.trim() !== confirmPassword.trim()) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('Passwords do not match');
        return;
      }
    }
    setIsLoading(true);

    Auth.forgotPasswordSubmit(isValidEmail, isValidCode, isValidPassword)
      .then((result) => {
        Auth.signIn({ username: isValidEmail.toLowerCase(), password })
          .then(async (res) => {
            const auth = res.signInUserSession;
            if (!auth) {
              throw new Error('Invalid credentials');
            }

            appState.handleUserSession(auth);
            window.location.href = '/';
          })
          .catch((err) => {
            if (err.code === 'NotAuthorizedException') {
              setError(err.message);
            }
            setError('Invalid credentials');
          });
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledWrapper>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Heading tag='h1' level={4} css='margin-bottom: 1rem; display: flex; justify-content: center;margin-bottom: 32px;'>
          <img className='img' src={logo} alt='Universal Practice' style={{ maxWidth: '230px' }} />
        </Heading>
        <FormField
          field={Input}
          label='EMAIL'
          value={email}
          fieldProps={{
            type: 'email',
            name: 'email',
            placeholder: 'Enter email',
            className: 'up-input',
            maxLength: 254,
          }}
          errorText='Please enter a valid email'
          error={emailError}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setEmail(e.target.value);
            setEmailError(!EmailRegex.test((e.target.value || '').trim()));
          }}
        />
        <FormField
          field={Input}
          label='VERIFICATION CODE'
          value={code}
          fieldProps={{
            type: 'number',
            name: 'code',
            placeholder: 'Enter verification code',
            className: 'up-input',
            maxLength: 10,
            autocomplete: 'off',
          }}
          errorText='Please enter a verification code from your email'
          error={codeError}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setCode(e.target.value);
            setCodeError(!(e.target.value || '').trim());
          }}
        />
        <FormField
          field={Input}
          label='NEW PASSWORD'
          value={password}
          fieldProps={{
            name: 'password',
            placeholder: 'Enter New Password',
            type: 'password',
            className: 'up-input',
            maxLength: 25,
            autocomplete: 'new-password',
          }}
          errorText='Please enter a valid password'
          error={passwordError}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setPassword(e.target.value);
            setPasswordError(!(e.target.value || '').trim());
          }}
        />
        <FormField
          field={Input}
          label='CONFIRM PASSWORD'
          value={confirmPassword}
          fieldProps={{
            name: 'confirm-password',
            placeholder: 'Confirm Password',
            type: 'password',
            className: 'up-input',
            maxLength: 25,
            autocomplete: 'new-password',
          }}
          errorText={confirmPasswordErrorText}
          error={confirmPasswordError}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setConfirmPassword(e.target.value);
            setConfirmPasswordError(!(e.target.value || '').trim());
          }}
        />
        <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
          <strong
            style={{
              fontSize: ' 0.75rem',
              marginTop: '0.5rem',
              color: '#FF8B8A',
            }}
          >
            {error}
          </strong>
          <UPButton style={{ width: '100%', marginTop: '50px' }} isLoading={isLoading}>
            Change Password
          </UPButton>
        </div>
      </form>
    </StyledWrapper>
  );
};

export default ChangetPasswordForm;
