import { FC, Fragment } from 'react';
import StyledUPButton from './Styled';

import Spinner from '../../components/icons/Spinner';

interface UpButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const UpButton: FC<UpButtonProps> = ({ isLoading, children, ...rest }) => {
  return (
    <StyledUPButton {...rest} disabled={isLoading}>
      {isLoading && <Spinner />}
      {!isLoading && <Fragment>{children}</Fragment>}
    </StyledUPButton>
  );
};

export default UpButton;
