import styled from 'styled-components';

export const StyledUPButton = styled.button`
  background: #ffffff;
  cursor: pointer;
  color: #000000;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 1px solid #ffffff;
  border-radius: 3px;
  height: 48px;

  /* ripple effect */
  background-position: center;
  transition: background 0.5s;

  &.inverse {
    background: #000000 !important;
    color: #ffffff !important;
  }
  &.simple {
    transition: none;
  }
  &.full-width {
    width: 100%;
  }
  &:hover {
    background: #e9e9e9 radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;
  }
  &:active {
    background-color: #b2b2b2;
    background-size: 100%;
    transition: background 0s;
  }
`;

export default StyledUPButton;
