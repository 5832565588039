import { Add } from '@material-ui/icons';
import { Flex, FormField, Grid, PrimaryButton, Select } from 'amazon-chime-sdk-component-library-react';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { EngagementEventSetting } from '../../constants/http';
import Base from '../../containers/Base';
import useAxios from '../../hooks/useAxios';
import { IResponse } from '../../types';
import { StyledLayout, StyledTableCell } from './Styled';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Spinner from '../../components/icons/Spinner';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { SelectOptions } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Select';
import { IEngagementEventSetting } from '../../types/IEngagementEventSetting';
import { useUPProduct } from '../../providers/UPProductProvider/UPProductProvider';
import { AppStateContext } from '../../providers/AppStateProvider';

interface CommunicationTracksFormProps {}
const CommunicationTracks: FC<CommunicationTracksFormProps> = (props) => {
  const [productOptions, setProductOptions] = useState<SelectOptions[] | undefined>([]);
  const [selectedProductOption, setSelectedProductOption] = useState('');
  const history = useHistory();
  const { productOptions: contextSetProductOptions } = useUPProduct();
  const { fetch: getEngagementEventSettings, loading: getEngagementEventSettingsLoading } = useAxios<IResponse<IEngagementEventSetting[]>>();
  const [rows, setRows] = useState<IEngagementEventSetting[]>([]);
  const stateContext = useContext(AppStateContext);
  const currentUserInfo = stateContext?.currentUserInfo;

  interface IColumnType {
    key: string;
    name: string;
  }
  const columns = [
    { key: 'day', name: 'Day' },
    { key: 'hour', name: 'Hour' },
    { key: 'emailTemplate', name: 'Email Template' },
    { key: 'notificationTitle', name: 'Notification Title' },
  ];
  useEffect(() => {
    if (contextSetProductOptions) setProductOptions(contextSetProductOptions);
  }, [contextSetProductOptions]);
  const productChangedHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const productId = event.target.value;
    setSelectedProductOption(productId);
    await getEngagementSettings(productId);
  };
  async function getEngagementSettings(productId: string) {
    if (!productId) {
      setRows([]);
      return;
    }
    const getProductsResult = await getEngagementEventSettings({
      url: EngagementEventSetting.GET_BY_TRACK.replace('{track}', productId),
      method: 'GET',
    });
    if (getProductsResult && getProductsResult.isSuccess && getProductsResult.result && getProductsResult.result.length > 0) {
      const communications = getProductsResult.result as IEngagementEventSetting[];
      communications.sort((n1, n2) => {
        if (n1.schedule.dayNumber > n2.schedule.dayNumber) {
          return 1;
        }

        if (n1.schedule.dayNumber < n2.schedule.dayNumber) {
          return -1;
        }

        if (n1.schedule.timeToSend > n2.schedule.timeToSend) {
          return 1;
        }

        if (n1.schedule.timeToSend < n2.schedule.timeToSend) {
          return -1;
        }

        return 0;
      });
      setRows(communications);
    } else setRows([]);
  }
  const handleSelectedRow = (row: IEngagementEventSetting) => {
    if (row.uniqueName) history.push(`/communications/${row.uniqueName}`);
  };
  return (
    <>
      {currentUserInfo && currentUserInfo.isHealthCoach && (
        <Base>
          <StyledLayout>
            <PageHeader title='Communication Tracks'></PageHeader>

            <Flex
              flexDirection='row'
              layout='fill-space'
              alignItems='initial'
              justifyContent='initial'
              flexWrap='wrap'
              style={{ marginLeft: 120, marginTop: 18, height: 'auto' }}
            >
              <Grid gridTemplateColumns='6fr 3fr 3fr' gridGap='16px'>
                <FormField
                  field={Select}
                  label={'Product'}
                  onChange={productChangedHandler}
                  value={selectedProductOption}
                  options={productOptions}
                  fieldProps={{ className: 'up-select' }}
                  layout='horizontal'
                />
                <Box style={{ textAlign: 'right' }}></Box>
                <Box style={{ textAlign: 'right' }}>
                  <PrimaryButton
                    label='Add'
                    icon={<Add />}
                    style={{ height: 42, width: '100%' }}
                    onClick={() => {
                      history.push('/communications/0');
                    }}
                  />
                </Box>
              </Grid>
            </Flex>
            <Flex style={{ marginLeft: 120, width: 840, marginTop: 16 }}>
              <TableContainer style={{ height: 600 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column: IColumnType) => (
                        <StyledTableCell key={column.key} component='th'>
                          {column.name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getEngagementEventSettingsLoading ? (
                      <TableRow>
                        <StyledTableCell colSpan='4' align='center'>
                          <Spinner />
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      <>
                        {rows.map((row: IEngagementEventSetting) => (
                          <TableRow key={row.uniqueName} onClick={() => handleSelectedRow(row)}>
                            <StyledTableCell scope='row'>{row.schedule.dayNumber}</StyledTableCell>
                            <StyledTableCell>{row.schedule.timeToSend}</StyledTableCell>
                            <StyledTableCell>{row.sendViaEmail?.emailContent?.template}</StyledTableCell>
                            <StyledTableCell>{row.sendViaPush?.pushNotificationContent?.title}</StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Flex>
          </StyledLayout>
        </Base>
      )}
    </>
  );
};

export default CommunicationTracks;
