import React, { ChangeEvent, FC, useState } from 'react';
import { FormField, Heading, Input } from 'amazon-chime-sdk-component-library-react';
import { StyledWrapper } from './Styled';
import logo from '../../assets/Universal_Practice_CMYK_White-300-100.png';
import UPButton from '../../components/UPButton';
import { EmailRegex } from '../../utils/common';
import { Auth } from 'aws-amplify';
import { ConsoleLogger } from 'amazon-chime-sdk-js';

interface ForgotPasswordFormProps {
  children?: React.ReactNode;
  onSuccess: (email: string) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidEmail = email.trim();

    if (!isValidEmail) {
      setEmailError(!isValidEmail);
      return;
    }

    if (isValidEmail && !EmailRegex.test(isValidEmail)) {
      setEmailError(!isValidEmail);
      return;
    }

    setIsLoading(true);

    Auth.forgotPassword(email.trim())
      .then((data) => onSuccess(email.trim()))
      .catch((err) => {
        if (err && err.message) {
          setError(err.message);
          setTimeout(() => setError(''), 3500);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledWrapper>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Heading tag='h1' level={4} css='margin-bottom: 1rem; display: flex; justify-content: center;margin-bottom: 32px;'>
          <img className='img' src={logo} alt='Universal Practice' style={{ maxWidth: '230px' }} />
        </Heading>
        <FormField
          field={Input}
          label='EMAIL'
          value={email}
          fieldProps={{
            type: 'email',
            name: 'email',
            placeholder: 'Enter email',
            className: 'up-input',
            maxLength: 254,
          }}
          errorText='Please enter a valid email'
          error={emailError}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setEmail(e.target.value);
            setEmailError(!EmailRegex.test((e.target.value || '').trim()));
          }}
        />

        <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
          <strong
            style={{
              fontSize: ' 0.75rem',
              marginTop: '0.5rem',
              color: '#FF8B8A',
            }}
          >
            {error}
          </strong>
          <UPButton style={{ width: '100%', marginTop: '50px' }} isLoading={isLoading}>
            Forgot Password
          </UPButton>
        </div>
      </form>
    </StyledWrapper>
  );
};

export default ForgotPasswordForm;
