export const CRMFormTypes = {
  RADIOBUTTONS: 'radiobuttons',
  CHECKBOXES: 'checkboxes',
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  DATE: 'date',
  SIGNATURE: 'signature',
  BODY_CHARTS: 'bodycharts',
};

export const CRMFormNames = {
  TREATMENT_NOTE_FORM_TEMPLATE: 'Virtual LBP Initial',
};
