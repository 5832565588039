import styled from 'styled-components';

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 auto;
  max-width: 960px;
  padding: 0;

  input,
  textarea {
    border: 1px solid;
    font-family: Arial, Helvetica;
  }
  .submit-button {
    label {
      color: #000000;
    }
  }
  .delete-button {
    background-color: rgb(125, 129, 139);
    border-color: rgb(125, 129, 139);
    label {
      color: #ffffff;
    }
  }
`;
