import { FC } from 'react';
import StyledLayout from './Styled';

import Spinner from '../../components/icons/Spinner';
import { LoaderContainer } from '../../views/LoadMeeting/Styled';
import { Heading } from 'amazon-chime-sdk-component-library-react';

interface PageHeaderProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  title: string;
}

const PageHeader: FC<PageHeaderProps> = ({ isLoading, title }) => {
  return (
    <StyledLayout>
      <Heading level={6} tag='h6' mb={2}>
        {title}
      </Heading>
      {isLoading && (
        <LoaderContainer>
          <Spinner size={3.5} />
          <div>Please wait...</div>
        </LoaderContainer>
      )}
    </StyledLayout>
  );
};

export default PageHeader;
