/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

import { absoluteCenter, visuallyHidden } from '../../../utils/style';

export const HiddenRadio = styled.input`
  ${visuallyHidden};

  &[aria-invalid='true'] + .ch-radio {
    border: ${(props) => props.theme.inputs.error.border};
    box-shadow: ${(props) => props.theme.inputs.error.shadow};
  }
`;

export const StyledRadioWrapper = styled.span`
  > label {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const StyledRadio = styled.div<any>`
  background-color: transparent;
  border: ${(props) => props.theme.inputs.border};
  border-radius: 50%;
  box-shadow: ${(props) => props.theme.inputs.checked.shadow};
  margin-bottom: -0.1875rem;
  transition: box-shadow 0.05s ease-in;
  cursor: pointer;

  ${HiddenRadio}:checked ~ & {
    background-color: transparent;
    box-shadow: ${(props) => props.theme.inputs};
    border-radius: 50%;
  }

  ${HiddenRadio}:focus ~ & {
    border: ${(props) => props.theme.inputs.checked.shadow};
    box-shadow: ${(props) => props.theme.inputs.checked.shadow};
  }
`;

export const StyledRadioLabel = styled(StyledRadio)<any>`
  display: inline-block;
  height: 1rem;
  position: relative;
  width: 1rem;
  cursor: pointer;

  &:after {
    background-color: ${(props) => (props.checked ? props.theme.inputs.checked.bgd : 'transparent')};
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.375rem;
    padding: 0.03125rem;
    width: 0.375rem;
    ${absoluteCenter};
  }
`;

export const StyledRadioIcon = styled(StyledRadio)<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-color: ${(props) => props.theme.colors.greys.white};
  box-shadow: none;
  margin: 0.1rem;
  cursor: pointer;

  ${HiddenRadio}:checked ~ & {
    svg {
      stroke: ${(props) => props.theme.colors.greys.white};
    }
  }
`;
