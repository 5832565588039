import { ROLES } from './../constants/index';
import { User } from '../constants/http';
import { IUserResponse } from '../types';

const processENV = process.env;
export const BASE_URL = processENV.REACT_APP_EXP_API_BASE_URL;
export const DEFAULT_REGION = processENV.REACT_APP_DEFAULT_REGION || 'ap-southeast-2';
export const EXP_API_KEY = processENV.REACT_APP_EXP_API_KEY;
export const AUTO_LOGIN = processENV.REACT_APP_EXP_API_AUTO_LOGIN;
export const CLINIKO = processENV.REACT_APP_CLINIKO_WEB;
export const REGISTRATION_URL = processENV.REACT_APP_REGISTRATION_URL;
export const INTERCOM_APP = processENV.REACT_APP_INTERCOM_APP;

export type MeetingFeatures = {
  Audio: { [key: string]: string };
};

export type CreateMeetingResponse = {
  MeetingFeatures: MeetingFeatures;
  MediaRegion: string;
};

export type JoinMeetingInfo = {
  Meeting: CreateMeetingResponse;
  Attendee: string;
};

interface MeetingResponse {
  JoinInfo: JoinMeetingInfo;
}

interface GetAttendeeResponse {
  name: string;
}

export async function fetchMeeting(meetingId: string, name: string, region: string, echoReductionCapability = false): Promise<MeetingResponse> {
  const params = {
    title: encodeURIComponent(meetingId.toLowerCase()),
    name: encodeURIComponent(name),
    region: encodeURIComponent(region),
    ns_es: String(echoReductionCapability),
  };

  const res = await fetch(`${BASE_URL}/join?${new URLSearchParams(params)}`, {
    method: 'POST',
  });
  const data = await res.json();
  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}

export async function getAttendee(meetingId: string, chimeAttendeeId: string): Promise<GetAttendeeResponse> {
  const params = {
    title: encodeURIComponent(meetingId.toLowerCase()),
    attendee: encodeURIComponent(chimeAttendeeId),
  };

  const res = await fetch(`${BASE_URL}/attendee?${new URLSearchParams(params)}`, { method: 'GET' });
  if (!res.ok) {
    throw new Error('Invalid server response');
  }

  const data = await res.json();

  return {
    name: data.AttendeeInfo.Name,
  };
}

export async function endMeeting(meetingId: string): Promise<void> {
  const params = {
    title: encodeURIComponent(meetingId.toLowerCase()),
  };

  const res = await fetch(`${BASE_URL}/end?${new URLSearchParams(params)}`, {
    method: 'POST',
  });
  if (!res.ok) {
    throw new Error('Server error ending meeting');
  }
}

export async function getNearestRegion() {
  try {
    const res = await fetch('https://nearest-media-region.l.chime.aws', {
      method: 'GET',
    });
    if (!res.ok) {
      throw new Error('Server error');
    }

    const data = await res.json();
    return data.region;
  } catch (e) {
    console.error('Could not fetch nearest region: ', (e as Error).message);
    return DEFAULT_REGION;
  }
}

export const createGetAttendeeCallback =
  (meetingId: string) =>
  (chimeAttendeeId: string): Promise<GetAttendeeResponse> =>
    getAttendee(meetingId.toLowerCase(), chimeAttendeeId);

export async function autoLogin(token: string) {
  try {
    const res = await fetch(`${AUTO_LOGIN}/${token}`, {
      method: 'GET',
    });
    if (!res.ok) {
      throw new Error('Invalid server response');
    }

    const data = await res.json();

    return data;
  } catch (e) {
    return null;
  }
}

export const getCurrentUser = async (token: string) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': EXP_API_KEY || '',
    Authorization: `Bearer ${token}`,
  };
  const options = {
    method: 'GET',
    headers: new Headers(headers),
  };

  const userProm = await fetch(`${BASE_URL}${User.GET}`, options);
  const user: IUserResponse = await userProm.json();
  if (!user.isSuccess) {
    return;
  }

  const role = user?.result?.crmPractitionerId ? ROLES.PRACTITIONER : ROLES.PATIENT;
  return { ...user.result, role };
};
