import { darkTheme } from 'amazon-chime-sdk-component-library-react';

const { colors: darkThemeColors, shadows: darkThemeShadows } = darkTheme;

const chatDarkTheme = {
  title: darkThemeColors.greys.white,
  primaryText: darkThemeColors.greys.white,
  secondaryText: darkThemeColors.greys.grey20,
  headerBorder: darkThemeColors.greys.black,
  containerBorder: darkThemeColors.greys.black,
  bgd: darkThemeColors.greys.grey100,
  fgd: darkThemeColors.greys.grey60,
  shadow: darkThemeShadows.large,
  maxWidth: '18.5rem',
};

const WHITE = '#ffffff';
const BLACK = '#000000';

darkTheme.colors.primary.darker = WHITE;

const iconButtonKeys = ['hover', 'focus', 'active'];
for (const key of iconButtonKeys) {
  darkTheme.buttons.icon[key].bgd = WHITE;
  darkTheme.buttons.icon[key].border = WHITE;
  darkTheme.buttons.icon[key].shadow = WHITE;
  darkTheme.buttons.primary[key].bgd = WHITE;
  darkTheme.buttons.primary[key].border = WHITE;
  darkTheme.buttons.primary[key].shadow = WHITE;
}

darkTheme.inputs.bgd = BLACK;

darkTheme.inputs.checked.border = BLACK;
darkTheme.inputs.checked.bgd = WHITE;
darkTheme.inputs.checked.shadow = `0 0 0 0.15rem ${WHITE}`;

darkTheme.buttons.primary.static.bgd = WHITE;
darkTheme.buttons.primary.static.border = WHITE;
darkTheme.buttons.primary.static.shadow = WHITE;
darkTheme.buttons.primary.static.text = BLACK;

export const upDarkTheme = {
  ...darkTheme,
  globalStyle: {
    bgd: BLACK,
    text: WHITE,
    fontSize: darkTheme.globalStyle.fontSize,
  },
  chat: chatDarkTheme,
};
