/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger, POSTLogger } from 'amazon-chime-sdk-js';

export type Obj = Record<string, any>;

export type FormattedDeviceType = {
  deviceId: string;
  label: string;
};

export type DeviceType = MediaDeviceInfo | FormattedDeviceType;

export type SelectedDeviceType = string | null;

export type DeviceTypeContext = {
  devices: DeviceType[];
  selectedDevice: SelectedDeviceType;
};

export type LocalVideoContextType = {
  isVideoEnabled: boolean;
  toggleVideo: () => Promise<void>;
};

export type DeviceConfig = {
  additionalDevices?: boolean;
};

export type LocalAudioOutputContextType = {
  isAudioOn: boolean;
  toggleAudio: () => void;
};

export type ContentShareControlContextType = {
  isContentSharePaused: boolean;
  toggleContentShare: () => Promise<void>;
  togglePauseContentShare: () => void;
};

export enum MeetingMode {
  Spectator,
  Attendee,
}

export enum Layout {
  Gallery,
  Featured,
}

// Different CPU Utilizations percentage options for initializing background blur and replacement processors
export const VideoFiltersCpuUtilization = {
  Disabled: '0',
  CPU10Percent: '10',
  CPU20Percent: '20',
  CPU40Percent: '40',
};

// Video Transform Options
export const VideoTransformOptions = {
  None: 'None',
  Blur: 'Background Blur',
  Replacement: 'Background Replacement',
  UP: 'UP',
};

export type VideoTransformDropdownOptionType = {
  label: string;
  value: string;
};

export type MeetingConfig = {
  simulcastEnabled: boolean;
  logger: Logger;
  postLogger?: POSTLogger; // Keep track of POSTLogger to update meeting metadata while joining a meeting.
};

export type AuthTokenPayload = {
  sub: string;
  email_verified: boolean;
  iss: string;
  origin_jti: number;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: string;
  exp: number;
  iat: number;
  jti: number;
  email: string;
};

export type AuthStatus = {
  idToken: {
    jwtToken: string;
    payload: AuthTokenPayload;
  };
  refreshToken: {
    token: string;
  };
  accessToken: {
    jwtToken: string;
    payload: AuthTokenPayload;
  };
  clockDrift: number;
};

export interface IResponse<R = unknown> {
  isSuccess: boolean;
  statusCode: number;
  result: R;
  errorMessages: Array<{ code: string; message: string }> | null;
}

export interface IUserResponseResult {
  email: string;
  crmPatientId: string;
  crmPractitionerId: string;
  firstName: string;
  lastName: string;
  externalId: string;
  userName: string;
  isCrmUser: boolean;
  isHealthCoach: boolean | undefined;
  timezone: string;
}
export interface IPaginatedResult<R = unknown> {
  page: number;
  items: R;
  totalItems: number;
}
export interface IPatientResponseResult {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  patientPhoneNumbers: IPhoneResult[];
  timeZone: string;
  dob: string;
  acceptedPrivacyPolicy: boolean | null;
  dvaCardNumber: string;
  medicare: string;
  customFields: ICustomFieldResult;
}
export interface IPatientInsuranceDetails {
  insurer: string | undefined;
  privateHealthInsuranceName: string | undefined;
  membershipNumber: string | undefined;
}
export interface IPhoneResult {
  phoneType: string;
  number: string;
}

export interface ICustomFieldResult {
  sections: ICustomFieldSectionResult[];
}

export interface ICustomFieldSectionResult {
  name: string;
  token: string;
  fields: ICustomFieldSectionFieldResult[];
}

export interface ICustomFieldSectionFieldResult {
  name: string;
  token: string;
  value: string;
  options: ICustomFieldSectionFieldOptionResult[] | null;
}

export interface ICustomFieldSectionFieldOptionResult {
  name: string;
  token: string;
  selected: boolean;
}

export interface IPatientForm {
  id: string;
  created_at: string;
  name: string;
  completed_at: string;
  content: IPatientFormContent;
}

export interface IPatientFormContent {
  sections: Obj[];
}
export interface IPatientTreatmentNote {
  id: string;
  created_at: string;
  title: string;
  finalized_at: string;
  content: IPatientTreatmentNoteContent;
  author_name: string;
}

export interface IPatientTreatmentNoteContent {
  sections: Obj[];
}

export type IUserResponse = IResponse<IUserResponseResult>;

export interface IUser extends IUserResponseResult {
  role: string;
}

export interface IPatientInfo {
  username: string;
}

export type IUserAppointmentsResponse = IResponse<IUserAppointments[]>;

export interface IUserAppointments {
  id: string;
  patientName: string;
  practitionerId: string;
  patientId: string;
  appointmentTypeId: string;
  appointmentTypeName: string;
  startTime: string;
  endTime: string;
  practitionerExternalId: string;
  patientExternalId: string;
  practitionerName: string;
}

export interface IAppointmentDict {
  [key: string]: IUserAppointments[];
}

export interface URLParamsWaitingRoom {
  patientExternalId: string;
}

export interface ICognitoData {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

export interface ICognitoTokenData {
  name: string;
  value: string;
}

export interface IUserTokenSaveRequest {
  userName: string;
  data: ICognitoTokenData[] | null;
}

export type EventMessage = {
  eventType: string;
  data: any;
};

export type EventExcerciseChangedData = {
  excerciseName: string;
};

export type EventProductRecommendedData = {
  id: string;
  name: string;
  price: string;
  couponCode: string;
  couponInfo: string;
  duration: string;
  group: string;
  referrerId: string | undefined;
  referralName: string | undefined;
};

export enum EventTypes {
  ExcerciseChanged = 'ExcerciseChanged',
  MeetingStarted = 'MeetingStarted',
  ProductRecommended = 'ProductRecommended',
}

export type IDName<T = string> = {
  id: string;
  name: string;
} & {
  [key: string]: T;
};

export interface IExercise {
  category: string;
  thumbnailImageUrl: string;
  targets: string;
  goalRequirements: IGoalRequirement[];
  difficultLevel: number;
  partner: string;
  instruction: string[];
  externalContentKey: string;
  workoutProps: string;
  duration: number;
  videoLink: string;
  excerciseTaskId: string;
  title: string;
  locale: string;
  description: string;
  goalAssociatedTask: boolean;
  sessionTaskId: string;
  bodyparts: string[];
  records: IRecord[];
  completed: boolean;
}

export type IExercises = IResponse<IExercise[]>;

export interface IGoalRequirement {
  goalRequirement: number;
  displayOrder: number;
  goalName: string;
  taskGoalId: string;
  reps: number;
}

export type IUpdatedExercise = IResponse<string>;

export interface ISelectedExercise {
  patientExternalId: string;
  workoutDaysPerWeek: number;
  appointmentId: string;
  exercise: IExercise[];
}

export interface ISubscription {
  id: string;
  name: string;
  subscriptionId: string;
  paymentGateway: string;
  dateFirstPaymentDue: string;
  commenceDate: string;
  cancellationDate: string;
  subscriptionEndDate: string;
  trialStartDate: string;
  trialEndDate: string;
  nextBillingDate: string;
  isActive: boolean;
  appName: string;
  subscriptionStatus: string;
  trialStatus: string;
  isVip: boolean;
  createdDate: string;
  modifiedDate: string;
  subscriptionType: string;
  subscriptionOption: string;
  appointmentAllowance: number;
  appointmentLeft: number;
  subscriptionData: ISubscriptionData[];
  subscriptionAmountPerPeriod: number;
  subscriptionInterval: number;
  subscriptionIntervalUnit: string;
  subscriptionPeriodDaysCalculated: number;
}
export interface ISubscriptionData {
  name: string;
  value: string;
}
interface IAppointmentLeftResult {
  emailAddress: string;
  subscriptions: ISubscription[];
}

export interface IProduct {
  id: string;
  name: string;
  isActive: boolean;
  subscriptionType: string;
  subscriptionOption: string;
  appointmentAllowance: number;
  subscriptionAmountPerPeriod: number;
  subscriptionInterval: number;
  subscriptionIntervalUnit: string;
  subscriptionPeriodDaysCalculated: number;
  priceInformation: string;
  installmentPriceInformation: string;
  group: string;
  isRehabilitationProgram: boolean | null;
  isStrengthProgram: boolean | null;
  isPregnancyProgram: boolean | null;
}

export type IAppointmentLeft = IResponse<IAppointmentLeftResult>;

export interface IAppointmentDetails {
  id: string;
  patientName: string;
  practitionerId: string;
  patientId: string;
  appointmentTypeId: string;
  appointmentTypeName: string;
  appointmentTypeCode: string;
  defaultTreatmentNoteTemplateId: string;
  startTime: string;
  endTime: string;
  practitionerExternalId: any;
  patientExternalId: any;
  practitionerName: any;
}

export type IAppointments = IResponse<IAppointmentDetails>;
export type ITokenResponse = IResponse<string>;

export interface IStartedMeetingAttendee {
  attendeeId: string;
  capabilities: any;
  externalUserId: string;
  joinToken: string;
  attendeeName: string;
}
export interface IStartedMeetingResult {
  meeting: IStartedMeeting;
  attendees: IStartedMeetingAttendee[];
}

export interface IStartedMeetingMediaPlacement {
  audioFallbackUrl: string;
  audioHostUrl: string;
  eventIngestionUrl: string;
  screenDataUrl: string;
  screenSharingUrl: string;
  screenViewingUrl: string;
  signalingUrl: string;
  turnControlUrl: string;
}

export interface IStartedMeeting {
  externalMeetingId: string;
  mediaPlacement: IStartedMeetingMediaPlacement;
  mediaRegion: string;
  meetingArn?: string;
  meetingFeatures?: unknown;
  meetingHostId?: string;
  meetingId: string;
  primaryMeetingId?: string;
  tenantIds: unknown[];
}

export type IStartedMeetingResponse = IResponse<IStartedMeetingResult>;

export type ISessionResponse = IResponse<ISession[]>;

export interface ISession {
  sessionTasks: IExercise[];
  sessionId: string;
  title: string;
  description: string;
  dayDisplay: string;
  thumbnailImageUrl: string;
  externalContentKey: string;
}

export interface ISubscriptionProgram {
  scheduleId: string;
  title: string;
  headline: string;
  description: string;
  thumbnailImageUrl: string;
  fixedDuration: boolean;
  durationInWeeks: number;
  workoutDaysPerWeek: number;
  viewDaysBack: number;
  viewDaysForward: number;
  pastSessionsEnabled: boolean;
  programStartUtc: string | null | undefined;
  programEndUtc: string | null | undefined;
  sessions: ISession[];
}

export type PresetProgramsResponse = IResponse<ISubscriptionProgram[]>;

export type UserProgramResponse = IResponse<ISubscriptionProgram>;

export interface IValue {
  value: string;
}

export type UserProgressResponse = IResponse<IUserProgramProgress>;

export interface IUserProgramProgress {
  programId: string;
  personalisedProgressList: IPersonalisedProgressList[];
}

export interface IPersonalisedProgressList {
  sessionId: string;
  title: string;
  weekTitle: string;
  completed: boolean;
  weekStarting: string;
  exercises: IExercise[];
}

export interface IExercisesProgress {
  exerciseTitle: string;
  progress: IProgressSession[];
}

export interface IProgressSession {
  sessionTitle: string;
  exercise: IExercise;
}
export interface IRecord {
  id: string;
  recordUtc?: string;
  reps: number;
  goalName: string;
  goalMet: boolean;
  requiredReps: number;
  day: string;
}

export enum TokenPurposes {
  ProductRecommendation = 'ProductRecommendation',
  Rebooking = 'Rebooking', //Not used
  Meeting = 'Meeting',
  NewConditionBooking = 'NewConditionBooking',
  FollowUpBooking = 'FollowUpBooking',
  RiskAssessment = 'RiskAssessment',
}

export enum NotificationActions {
  None = 'None',
  OpenDeeplink = 'OpenDeeplink',
  OpenApp = 'OpenApp', //Not used
}

export enum CardTypes {
  InteractiveUpAtHomeCard = 'InteractiveUpAtHomeCard',
  ArticleCard = 'ArticleCard',
}

export interface IColourMap {
  [key: string]: string;
}

export enum CommissionTypes {
  Appointment = 'Appointment',
  Referral = 'Referral',
  Trailing = 'Trailing',
}

export interface ITableColumnType {
  key: string;
  name: string;
  visible: boolean;
}
