import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';

import React, { createContext, FC, useContext, useState } from 'react';

interface Props extends BaseSdkProps {
  children: React.ReactNode;
}

const UPTreatmentNotesDialogContext = createContext<{
  showTreatmentNoteDialog: boolean;
  setShowTreatmentNoteDialog: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  showTreatmentNoteDialog: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowTreatmentNoteDialog: () => {},
});

export const UPTreatmentNotesDialogProvider: FC<Props> = ({ children }) => {
  const [showTreatmentNoteDialog, setShowTreatmentNoteDialog] = useState<boolean>(false);
  return (
    <UPTreatmentNotesDialogContext.Provider value={{ showTreatmentNoteDialog, setShowTreatmentNoteDialog }}>{children}</UPTreatmentNotesDialogContext.Provider>
  );
};

export const useUPTreatmentNotesDialog = () => useContext(UPTreatmentNotesDialogContext);
