import { lazy } from 'react';

export const rootPath = '/';

const Home = lazy(() => import('../views/Home'));
const Device = lazy(() => import('../views/DeviceSetup'));
const LoadMeeting = lazy(() => import('../views/LoadMeeting'));
const WaitingRoom = lazy(() => import('../views/WaitingRoom'));
const Meeting = lazy(() => import('../views/Meeting'));
// const UpComingAppointments = lazy(() => import('../views/UpComingAppointments'));
const RecoveryRoom = lazy(() => import('../views/RecoveryRoom'));
const SearchPatient = lazy(() => import('../views/SearchPatient'));
const Patient = lazy(() => import('../views/Patients'));
const LoadMessenger = lazy(() => import('../views/LoadMessenger'));
const PractitionerDiary = lazy(() => import('../views/PractitionerDiary'));
const YourBusiness = lazy(() => import('../views/YourBusiness'));
import { CommunicationWrapper } from '../views/CommunicationWrapper';

const privateRoutes = [
  {
    key: 1,
    path: '/',
    component: Home,
    exact: true,
  },
  {
    key: 2,
    path: '/meeting/:meetingId',
    component: LoadMeeting,
  },
  {
    key: 3,
    path: '/device',
    component: Device,
    noMeetingRedirect: true,
  },
  {
    key: 4,
    path: '/meeting-room/:meetingId',
    component: Meeting,
  },
  {
    key: 5,
    path: '/appointments',
    component: PractitionerDiary,
  },
  {
    key: 6,
    path: '/waiting-room/:patientExternalId',
    component: WaitingRoom,
  },
  {
    key: 7,
    path: '/recovery-room',
    component: RecoveryRoom,
  },
  {
    key: 8,
    path: '/search-patients',
    component: SearchPatient,
  },
  {
    key: 9,
    path: '/patients/:patientId',
    component: Patient,
  },
  {
    key: 10,
    path: '/messenger',
    component: LoadMessenger,
  },
  {
    key: 11,
    path: '/communication-tracks',
    component: CommunicationWrapper,
  },
  {
    key: 12,
    path: '/communications/:communicationId',
    component: CommunicationWrapper,
  },
  {
    key: 13,
    path: '/your-business',
    component: YourBusiness,
  },
];

export default privateRoutes;
