import { Button } from '@material-ui/core';
import { Flex, Modal, ModalBody, ModalHeader } from 'amazon-chime-sdk-component-library-react';
import { useEffect, useState } from 'react';
import { useAppState } from '../../providers/AppStateProvider';

interface LogoutTimerProps {
  isOpen: boolean;
  toggleOpen: (value: boolean) => void;
}

const LogoutTimer = (props: LogoutTimerProps) => {
  const { refreshToken, logout } = useAppState();
  const [timer, setTimer] = useState(60);
  const [intervalHandler, setIntervalHandler] = useState<NodeJS.Timer | undefined>();

  useEffect(() => {
    if (props.isOpen) {
      let countdown = timer;
      const handler = setInterval(() => {
        setTimer(countdown);
        countdown--;
      }, 1000);

      setIntervalHandler(handler);
    } else {
      cancelCountdown();
    }

    return () => setIntervalHandler(undefined);
  }, [props.isOpen]);

  useEffect(() => {
    if (timer <= 0) {
      cancelCountdown();
      handleLogout();
    }
  }, [timer]);
  const cancelCountdown = () => {
    if (intervalHandler) clearInterval(intervalHandler);
  };

  const handleLogout = () => {
    logout();
    props.toggleOpen(false);
  };

  return (
    <>
      {props.isOpen && (
        <Modal size='md'>
          <ModalBody>
            <Flex layout='fill-space-centered' style={{ padding: 20 }}>
              <div>Your session is about to expired in {timer} seconds.</div>
            </Flex>
            <Flex layout='fill-space-centered' style={{ gap: 20, padding: 20 }}>
              <Button variant='contained' onClick={refreshToken}>
                {'Stay Login'}
              </Button>
              <Button variant='outlined' style={{ color: '#fff', borderColor: '#fff' }} onClick={handleLogout}>
                {'Logout'}
              </Button>
            </Flex>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default LogoutTimer;
