import { Menu as MenuContainer, MenuItem } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Flex, IconButton, LeaveMeeting } from 'amazon-chime-sdk-component-library-react';
import { BaseProps, FocusableProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledHeader } from './StyledHeader';

import upLogo from '../../assets/Universal_Practice_CMYK_White_150-50.png';
import { AppStateContext } from '../../providers/AppStateProvider';
import { clearPatientAppointmentStorage } from '../../utils/common';
import { ROLES } from '../../constants';

export interface UPNavbarHeaderProps extends BaseProps, FocusableProps {
  title: string;
  isDesktop: boolean;
  logout?: () => void;
}

export const UPNavbarHeader: React.FC<UPNavbarHeaderProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const stateContext = useContext(AppStateContext);
  const currentUser = stateContext?.currentUserInfo;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const navigateTo = (route: string) => {
    setAnchorEl(null);
    history.push(route);
  };

  return (
    <StyledHeader {...props}>
      <Flex container alignItems='center'>
        {!props.isDesktop && props?.logout && currentUser?.role && currentUser?.role === ROLES.PRACTITIONER && (
          <div>
            <IconButton aria-label='menu' label='menu' icon={<Menu />} onClick={handleClick} />
            <MenuContainer id='basic-menu' anchorEl={anchorEl} open={open} style={{ top: 36 }}>
              <MenuItem onClick={() => navigateTo('/appointments')}>Appointments</MenuItem>
              <MenuItem onClick={() => navigateTo('/search-patients')}>Search Patient</MenuItem>
              {currentUser?.isHealthCoach && <MenuItem onClick={() => navigateTo('/communication-tracks')}>Communication Tracks</MenuItem>}
              <MenuItem onClick={() => navigateTo('/your-business')}>Your Business</MenuItem>
            </MenuContainer>
          </div>
        )}
        {!props.isDesktop && currentUser?.role && currentUser?.role === ROLES.PATIENT && (
          <div>
            <IconButton aria-label='menu' label='menu' icon={<Menu />} onClick={handleClick} />
            <MenuContainer id='basic-menu' anchorEl={anchorEl} open={open} style={{ top: 36 }}>
              <MenuItem onClick={() => navigateTo('/')}>Home</MenuItem>
            </MenuContainer>
          </div>
        )}
        <img
          src={upLogo}
          style={{ height: '30px', cursor: 'pointer' }}
          onClick={(evt) => {
            if (currentUser && currentUser.role === ROLES.PATIENT) clearPatientAppointmentStorage();
            navigateTo('/');
          }}
        />
        <span>{props.title}</span>
      </Flex>

      {props?.logout && (
        <Flex container alignItems='center'>
          {props.isDesktop && <span>{`Welcome ${currentUser?.firstName}`}</span>}
          <IconButton
            className='ch-btn-close'
            label='Sign out'
            onClick={props.logout}
            icon={
              <strong style={{ cursor: 'pointer' }}>
                <LeaveMeeting />
              </strong>
            }
          />
        </Flex>
      )}
    </StyledHeader>
  );
};

export default UPNavbarHeader;
