import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
  & .SingleDatePicker,
  .SingleDatePickerInput {
    .DateInput {
      width: 100%;
      height: 40px;
      display: flex;

      .DateInput_input {
        font-size: 1rem;
        border-bottom: 0;
        padding: 12px 16px 14px;
        background: #1b1c20;
        border: #1b1c20;
        color: #ffffff;
      }
    }

    .SingleDatePickerInput__withBorder {
      border-radius: 4px;
      overflow: hidden;

      :hover,
      .DateInput_input__focused {
        border: 1px solid #ffffff;
      }

      .CalendarDay__selected {
        background: #1b1c20;
        border: #1b1c20;
      }
    }

    .SingleDatePicker_picker.SingleDatePicker_picker {
      top: 43px;
      left: 2px;
    }

    .CalendarDay {
      vertical-align: middle;
      text-align: center;
    }

    .DayPickerKeyboardShortcuts_buttonReset {
      display: none;
    }
  }
`;

export default StyledDatePickerWrapper;
