import { BASE_URL, EXP_API_KEY } from '../utils/api';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAppState } from '../providers/AppStateProvider';
import { useEffect, useState } from 'react';
import { isTokenExpired } from '../utils/auth';

let httpClient: AxiosInstance | undefined;

const useAxios = <T,>(reqParams?: AxiosRequestConfig) => {
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(!!reqParams);
  const [prevToken, setPrevToken] = useState('');

  const { authStatus, setAuthStatus } = useAppState();

  useEffect(() => {
    httpClient = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': EXP_API_KEY || '',
        Authorization: `Bearer ${authStatus?.accessToken?.jwtToken}`,
      },
    });

    const reqInterceptor = httpClient.interceptors.request.use(async (req) => {
      const isExpired = isTokenExpired(authStatus?.accessToken?.jwtToken);
      if (!isExpired) {
        return req;
      }

      setAuthStatus();
      // return req;
      /**
       * Need to implement refresh token logic here
       */

      // const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
      //   refresh: authStatus?.refreshToken.token,
      // });

      // localStorage.setItem('auth', JSON.stringify(response.data));
      // if (!req.headers) {
      //   return req;
      // }

      // req.headers.Authorization = `Bearer ${response.data.access}`;
      // req.headers['x-api-key'] = EXP_API_KEY || '';
      // return req;
    });

    return () => {
      httpClient && httpClient.interceptors.request.eject(reqInterceptor);
    };
  }, [authStatus]);

  const fetch = (params: AxiosRequestConfig) => {
    if (!httpClient) {
      return;
    }

    setLoading(true);
    return httpClient
      .request(params)
      .then((response) => {
        setResponse(response?.data);
        return response?.data;
      })
      .catch((error) => {
        setError(error?.response?.data);
        return error?.response?.data;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!reqParams) {
      return;
    }

    fetch(reqParams);
  }, [reqParams?.url]);

  return { response, error, loading, fetch };
};

export default useAxios;
