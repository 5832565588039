/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

export const UPHelper = styled.div<{ active: boolean }>`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: #ff8b8a;
  display: none;

  ${({ active }) => {
    return active && 'display:block ';
  }}
`;
