import 'react-dates/initialize';
import React from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';
import StyledDatePickerWrapper from './Styled';

type DPProps = Partial<SingleDatePickerShape> & {
  id: string;
  onChange: ((date: { target: { value?: string } }) => void) | undefined;
  date?: string;
  displayFormat: string;
  outputFormat?: string;
};

interface DPState {
  focused: boolean;
  date: moment.Moment | null;
}

export default class DatePicker extends React.Component<DPProps, DPState> {
  state: DPState = {
    focused: false,
    date: this.props.date ? moment(this.props.date, this.props.outputFormat || this.props.displayFormat) : null,
  };

  handleDateChange(date: moment.Moment | null) {
    this.setState({ date: date || null });
    this.props.onChange?.({ target: { value: date ? moment(date).format(this.props.outputFormat || this.props.displayFormat) : undefined } });
  }

  render() {
    const props = { ...this.props };
    delete props.onChange;
    delete props.outputFormat;

    return (
      <StyledDatePickerWrapper>
        <SingleDatePicker
          numberOfMonths={1}
          onFocusChange={({ focused }) => this.setState({ focused })}
          focused={this.state.focused}
          {...props}
          displayFormat={this.props.displayFormat}
          date={this.state.date}
          onDateChange={(date) => this.handleDateChange(date)}
        />
      </StyledDatePickerWrapper>
    );
  }
}
