import jwtDecode from 'jwt-decode';
import { CognitoUserPool, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { Obj, ICognitoData } from '../types';
import awsCognitoConfig from '../aws-cognito-config.json';

export const isTokenExpired = (token?: string): boolean => {
  try {
    if (!token) {
      return true;
    }

    const decodedToken = <Obj>jwtDecode(token);
    const currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log('Token expired.');
      return true;
    }

    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

export const cognitoAutoLogin = (cognitoData: ICognitoData) => {
  const userPool = new CognitoUserPool({
    UserPoolId: awsCognitoConfig.aws_user_pools_id,
    ClientId: awsCognitoConfig.aws_user_pools_web_client_id,
  });

  const cognitoIdToken = new CognitoIdToken({
    IdToken: cognitoData.idToken,
  });

  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: cognitoData.accessToken,
  });

  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: cognitoData.refreshToken,
  });

  const username = cognitoIdToken.payload.email;

  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const cognitoUserSession = new CognitoUserSession({
    AccessToken: cognitoAccessToken,
    IdToken: cognitoIdToken,
    RefreshToken: cognitoRefreshToken,
  });

  user.setSignInUserSession(cognitoUserSession);
};
