import { Input } from 'amazon-chime-sdk-component-library-react';
import { FC, useState } from 'react';
interface UPTextInputProps {
  className?: string;
  fieldName: string;
  fieldValue: string;
  index: number;
  onChange: (
    mainIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
    result?: boolean,
    subIndex?: number,
    reset?: boolean,
    other?: boolean,
    setValue?: (value: string) => void
  ) => void;
  readOnly: boolean;
}
const UPTextInput: FC<UPTextInputProps> = ({ className, fieldName, fieldValue, index, onChange, readOnly }) => {
  const [value, setValue] = useState(fieldValue || '');
  return (
    <Input
      className={className ? className : undefined}
      id={fieldName}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(index, e, undefined, undefined, undefined, undefined, setValue)}
      readOnly={readOnly}
    />
  );
};

export default UPTextInput;
