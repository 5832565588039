import { Flex, useMeetingManager, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { displayFormatDateTime } from '../../constants';
import { PatientForm } from '../../constants/http';
import useAxios from '../../hooks/useAxios';
import { useAppState } from '../../providers/AppStateProvider';
import { useUPFormPreview } from '../../providers/UPFormPreviewProvider/UPFormProvider';
import { IPatientForm, Obj } from '../../types';
import UPFormSection, { UPFormSectionDisplayModes } from '../UPFormComponents/UPFormSection';
import UPNonModalDialog from '../UPNonModalDialog';
interface IUPFormPreviewProps {
  children?: React.ReactChildren | React.ReactNode | string;
}
const UPFormPreview: FC<IUPFormPreviewProps> = (props) => {
  const { formId, setFormId } = useUPFormPreview();
  const { currentUserInfo } = useAppState();
  const [patientForm, setPatientForm] = useState<IPatientForm | null>();
  const { fetch: getPatientForm, loading: getPatientFormLoading, error: getPatientFormError } = useAxios<IPatientForm>();

  function handleOnCloseForm(): void {
    setFormId('');
    setPatientForm(null);
  }
  useEffect(() => {
    if (formId) initForm();
  }, [formId]);

  useEffect(() => {
    if (!currentUserInfo) handleOnCloseForm();
  }, [currentUserInfo]);

  const initForm = async () => {
    if (formId) {
      const url = PatientForm.GET.replace('{id}', formId);
      const getResult = (await getPatientForm({
        url: url,
        method: 'GET',
      })) as IPatientForm;
      if (getResult) {
        setPatientForm(getResult);
      }
    }
  };
  function handleFormChange(formData: Obj, index: number): void {
    // do nothing
  }
  return (
    <UPNonModalDialog
      showDialog={formId ? true : false}
      title={patientForm ? patientForm.name.replace('_', ' ') + ' ' + moment(patientForm.created_at).format(displayFormatDateTime) : ''}
      onCloseForm={() => handleOnCloseForm()}
    >
      {patientForm && patientForm.content && patientForm.content.sections?.length > 0 && (
        <Flex style={{ width: '100%' }}>
          {patientForm &&
            patientForm.content &&
            patientForm.content.sections.map((s, index) => {
              return (
                <>
                  <UPFormSection
                    questions={s.questions}
                    onChange={(formData, index) => handleFormChange(formData, index)}
                    sectionIndex={index}
                    displayMode={UPFormSectionDisplayModes.Condensed}
                  />
                </>
              );
            })}
        </Flex>
      )}
    </UPNonModalDialog>
  );
};
export default UPFormPreview;
