import React, { useState, useContext, useEffect, useRef, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';

import routes from '../constants/routes';

export type NavigationContextType = {
  showNavbar: boolean;
  showRoster: boolean;
  showChat: boolean;
  showTreatmentNotes: boolean;
  showBodyMappingSelector: boolean;
  showExercise: boolean;
  showProductRecommender: boolean;
  showPatientDetails: boolean;
  showPatientFormList: boolean;
  showPatientTreatmentNoteList: boolean;
  toggleRoster: () => void;
  toggleNavbar: () => void;
  openRoster: () => void;
  closeRoster: () => void;
  openNavbar: () => void;
  closeNavbar: () => void;
  closeAll: () => void;
  toggleChat: () => void;
  toggleTreatmentNotes: () => void;
  toggleBodyMappingSelector: () => void;
  toggleExercise: () => void;
  toggleProductRecommender: () => void;
  togglePatientDetails: () => void;
  togglePatientFormList: () => void;
  togglePatientTreatmentNoteList: () => void;
};

type Props = {
  children: ReactNode;
};

const NavigationContext = React.createContext<NavigationContextType | null>(null);

const isDesktop = () => window.innerWidth > 768;

const NavigationProvider = ({ children }: Props) => {
  const [showNavbar, setShowNavbar] = useState(() => isDesktop());
  const [showRoster, setShowRoster] = useState(false);
  const [showTreatmentNotes, setTreatmentNotes] = useState(false);
  const [showBodyMappingSelector, setShowBodyMappingSelector] = useState(false);
  const [showExercise, setExercise] = useState(false);
  const [showProductRecommender, setShowProductRecommender] = useState(false);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [showPatientFormList, setShowPatientFormList] = useState(false);
  const [showPatientTreatmentNoteList, setShowPatientTreatmentNoteList] = useState(false);
  // Turnoff chat
  const [showChat, setShowChat] = useState(false);
  const isDesktopView = useRef(isDesktop());

  const location = useLocation();
  const meetingManager = useMeetingManager();

  useEffect(() => {
    if (location.pathname.includes(routes[1].path)) {
      return () => {
        meetingManager.leave();
      };
    }
  }, [location.pathname]);

  useEffect(() => {
    const handler = () => {
      const isResizeDesktop = isDesktop();
      if (isDesktopView.current === isResizeDesktop) {
        return;
      }

      isDesktopView.current = isResizeDesktop;

      if (!isResizeDesktop) {
        setShowNavbar(false);
        setShowRoster(false);
        setTreatmentNotes(false);
        setShowBodyMappingSelector(false);
        setExercise(false);
        setShowProductRecommender(false);
        setShowPatientDetails(false);
        setShowPatientFormList(false);
        setShowPatientTreatmentNoteList(false);
        return;
      }

      setShowNavbar(true);
    };

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const openNavbar = (): void => {
    setShowNavbar(true);
  };

  const closeAll = (): void => {
    setShowRoster(false);
    setShowChat(false);
    setTreatmentNotes(false);
    setShowBodyMappingSelector(false);
    setExercise(false);
    setShowProductRecommender(false);
    setShowPatientDetails(false);
    setShowPatientFormList(false);
    setShowPatientTreatmentNoteList(false);
  };

  const closeNavbar = (): void => {
    setShowNavbar(false);
  };

  const openRoster = (): void => {
    closeAll();
    setShowRoster(true);
  };

  const closeRoster = (): void => {
    setShowRoster(false);
    setExercise(false);
  };

  const toggleNavbar = (): void => {
    setShowNavbar(!showNavbar);
  };

  const toggleRoster = (): void => {
    closeAll();
    setShowRoster(!showRoster);
  };

  const toggleChat = (): void => {
    closeAll();
    setShowChat(!showChat);
  };

  const toggleTreatmentNotes = (): void => {
    closeAll();
    setTreatmentNotes(!showTreatmentNotes);
  };

  const toggleBodyMappingSelector = (): void => {
    closeAll();
    setShowBodyMappingSelector(!showBodyMappingSelector);
  };

  const toggleExercise = (): void => {
    closeAll();
    setExercise(!showExercise);
  };

  const toggleProductRecommender = (): void => {
    closeAll();
    setShowProductRecommender(!showProductRecommender);
  };

  const togglePatientDetails = (): void => {
    closeAll();
    setShowPatientDetails(!showPatientDetails);
  };

  const togglePatientFormList = (): void => {
    closeAll();
    setShowPatientFormList(!showPatientFormList);
  };

  const togglePatientTreatmentNoteList = (): void => {
    closeAll();
    setShowPatientTreatmentNoteList(!showPatientTreatmentNoteList);
  };

  const providerValue = {
    showNavbar,
    showRoster,
    showChat,
    showTreatmentNotes,
    showBodyMappingSelector,
    showExercise,
    showProductRecommender,
    showPatientDetails,
    showPatientFormList,
    showPatientTreatmentNoteList,
    toggleRoster,
    toggleNavbar,
    openRoster,
    closeRoster,
    openNavbar,
    closeNavbar,
    closeAll,
    toggleChat,
    toggleTreatmentNotes,
    toggleBodyMappingSelector,
    toggleExercise,
    toggleProductRecommender,
    togglePatientDetails,
    togglePatientFormList,
    togglePatientTreatmentNoteList,
  };
  return <NavigationContext.Provider value={providerValue}>{children}</NavigationContext.Provider>;
};

const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw Error('Use useNavigation in NavigationProvider');
  }
  return context;
};

export { NavigationProvider, useNavigation };
