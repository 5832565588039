import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { NotificationProvider, GlobalStyles, LoggerProvider } from 'amazon-chime-sdk-component-library-react';
import { Amplify, Auth } from 'aws-amplify';

import { upDarkTheme } from './theme';
import { AppStateProvider } from './providers/AppStateProvider';
import ErrorProvider from './providers/ErrorProvider';
import Notifications from './containers/Notifications';
import MeetingProviderWrapper from './containers/MeetingProviderWrapper';
import meetingConfig from './meetingConfig';

import awsConfig from './aws-exports.json';
import awsCognitoConfig from './aws-cognito-config.json';
import appSyncConfig from './aws-appsync-config.json';
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP } from './utils/api';
import { UPFormPreviewProvider } from './providers/UPFormPreviewProvider/UPFormProvider';
import UPFormPreview from './components/UPFormPreview';
import { UPTreatmentNotePreviewProvider } from './providers/UPTreatmentNotePreviewProvider/UPTreatmentNotePreviewProvider';
import UPTreatmentNotePreview from './components/UPTreatmentNotePreview';
import { UPTreatmentNotesDialogProvider } from './providers/UPTreatmentNoteProvider/UPTreatmentNoteProvider';

const amplifyConfig = Object.assign({}, awsConfig, awsCognitoConfig);

Amplify.configure(amplifyConfig);
Amplify.configure(appSyncConfig);
Auth.configure({ storage: sessionStorage });
const App = (): JSX.Element => (
  <Router>
    <LoggerProvider logger={meetingConfig.logger}>
      <IntercomProvider appId={INTERCOM_APP ?? ''}>
        <AppStateProvider>
          <ThemeProvider theme={upDarkTheme}>
            <GlobalStyles />
            <NotificationProvider>
              <UPTreatmentNotePreviewProvider>
                <UPFormPreviewProvider>
                  <UPTreatmentNotesDialogProvider>
                    <Notifications />
                    <ErrorProvider>
                      <MeetingProviderWrapper />
                      <UPFormPreview />
                      <UPTreatmentNotePreview />
                    </ErrorProvider>
                  </UPTreatmentNotesDialogProvider>
                </UPFormPreviewProvider>
              </UPTreatmentNotePreviewProvider>
            </NotificationProvider>
          </ThemeProvider>
        </AppStateProvider>
      </IntercomProvider>
    </LoggerProvider>
  </Router>
);

export default App;
