export const MeetingServiceEndpoints = {
  CREATE: '/api/v1/meeting',
};

export const User = {
  GET: '/api/v1/user',
  POST: '/api/v1/user/searchbyemail',
};

export const TreatmentNote = {
  GET: '/api/v1/treatmentnotetemplates',
  GET_BY_ID: '/api/v1/treatmentnote/{id}',
  POST: '/api/v1/treatmentnote',
  PUT: '/api/v1/treatmentnote',
  POST_GET_DEFAULT_TREATMENT_NOTE: '/api/v1/treatmentnotetemplates/default/templateId',
};

export const Practitioner = {
  UPCOMING_APPOINTMENTS: '/api/v1/appointments/upcoming/practitioner',
  APPOINTMENTS: '/api/v1/appointments/practitioner',
};

export const UnavailableBlocks = {
  SEARCH: '/api/v1/unavailableblocks/search',
};

export const UnavailableBlock = {
  POST: '/api/v1/unavailableblock',
  ARCHIVE: '/api/v1/unavailableblock/{id}/archive',
};

export const Exercises = {
  GET: '/api/v1/exercises',
  POST: '/api/v1/program/personalised/enroll',
  GET_USER_PERSONALISED_PROGRAM: '/api/v1/program/personalised',
  GET_PROGRAMS: 'api/v1/program/subscription',
  GET_PROGRESS: 'api/v1/program/personalised/progress',
};

export const Session = {
  GET: 'api/v1/program/subscription',
};

export const Appointments = {
  GET: 'api/v1/appointments',
  GET_BY_PATIENT: '/api/v1/appointments/upcoming/patient',
};

export const UserSubscription = {
  GET: '/api/v1/usersubscription/active',
  GET_BY_APPOINTMENT: '/api/v1/usersubscription/active/appointment',
  GET_BY_PATIENT_ID: '/api/v1/usersubscription/active/patient',
};

export const GenerateTokenForAutoLogin = {
  POST: 'api/v1/userdata/token',
};

export const GetStartedMeeting = {
  GET: '/api/v1/meeting/appointment',
};

export const Patient = {
  GET_BY_ID: '/api/v1/patient/id',
};
export const Patients = {
  POST: '/api/v1/patients',
};

export const PatientForms = {
  SEARCH: '/api/v1/patientforms/search',
};

export const PatientTreatmentNotes = {
  SEARCH: '/api/v1/treatmentnotes/search',
};

export const PatientForm = {
  GET: '/api/v1/patientform/{id}',
};

export const SubscriptionPlans = {
  GET: '/api/v1/subscriptionplans',
};

export const EngagementEventSetting = {
  GET_BY_TRACK: '/api/v1/engagementeventsetting/track/{track}',
  GET: '/api/v1/engagementeventsetting/{uniqueName}/track/{track}',
  POST: '/api/v1/engagementeventsetting',
  REMOVE: '/api/v1/engagementeventsetting/remove',
};

export const UserFeed = {
  GET: '/api/v1/userfeed',
};

export const Entitlements = {
  SEARCH: '/api/v1/entitlements/search',
};
