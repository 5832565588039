import { Flex, FormField, Grid, Heading, Input, Label, Select, Textarea } from 'amazon-chime-sdk-component-library-react';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { EngagementEventSetting, SubscriptionPlans } from '../../constants/http';
import Base from '../../containers/Base';
import useAxios from '../../hooks/useAxios';
import { IResponse, IProduct, NotificationActions } from '../../types';
import { StyledLayout } from './Styled';

import PageHeader from '../../components/PageHeader';
import { SelectOptions } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Select';
import { IEngagementEventSetting } from '../../types/IEngagementEventSetting';
import UpButton from '../../components/UPButton';
import { useUPProduct } from '../../providers/UPProductProvider/UPProductProvider';
import { useParams } from 'react-router';
import { AppStateContext } from '../../providers/AppStateProvider';

interface CommunicationDetailsFormProps {}
const CommunicationDetails: FC<CommunicationDetailsFormProps> = (props) => {
  const { fetch: saveSetting, loading: saveSettingLoading } = useAxios<IResponse<string>>();
  const { fetch: deleteSetting, loading: deleteSettingLoading } = useAxios<IResponse<boolean>>();
  const [products, setProducts] = useState<IProduct[] | undefined>(undefined);
  const [productOptions, setProductOptions] = useState<SelectOptions[]>([]);
  const [selectedProductOption, setSelectedProductOption] = useState('');
  const [selectedProductOptionError, setSelectedProductOptionError] = useState<boolean>(false);
  const [scheduleOptions, setScheduleOptions] = useState<SelectOptions[]>([]);
  const [selectedScheduleOption, setSelectedScheduleOption] = useState('');
  const [scheduleDayOptions, setScheduleDayOptions] = useState<SelectOptions[]>([]);
  const [selectedScheduleDayOption, setSelectedScheduleDayOption] = useState('');
  const [emailTemplateName, setEmailTemplateName] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');

  const [notificationActionOptions, setNotificationActionOptions] = useState<SelectOptions[]>([]);
  const [selectedNotificationActionOption, setSelectedNotificationActionOption] = useState('');
  const [notificationLink, setNotificationLink] = useState('');

  const [message, setMessage] = useState('');
  const [uniqueName, setUniqueName] = useState('');
  const { products: contextProducts, productOptions: contextProductOptions } = useUPProduct();
  const { communicationId } = useParams<{ communicationId?: string }>();
  const { fetch: getEngagementEventSetting } = useAxios<IResponse<IEngagementEventSetting>>();
  const stateContext = useContext(AppStateContext);
  const currentUserInfo = stateContext?.currentUserInfo;

  useEffect(() => {
    const scheduleOptions: SelectOptions[] = [];
    for (let index = 0; index < 24; index++) {
      scheduleOptions[index] = {
        label: index.toString(),
        value: index,
      };
    }
    setScheduleOptions(scheduleOptions);

    const scheduleDayOptions: SelectOptions[] = [];
    for (let index = 1; index <= 365; index++) {
      scheduleDayOptions[index] = {
        label: index.toString(),
        value: index,
      };
    }
    setScheduleDayOptions(scheduleDayOptions);

    const notificationActionOptions: SelectOptions[] = [];
    let intActionIndex = 0;
    for (const [key, value] of Object.entries(NotificationActions)) {
      notificationActionOptions[intActionIndex] = {
        label: key,
        value: value,
      };
      intActionIndex++;
    }

    setNotificationActionOptions(notificationActionOptions);

    if (communicationId && communicationId !== '0') {
      const uniqueName = decodeURIComponent(communicationId);
      setUniqueName(uniqueName);
      getEngagementSettings(uniqueName);
    } else {
      setSelectedScheduleDayOption('1');
      setSelectedScheduleOption('0');
      setSelectedNotificationActionOption(NotificationActions.None.toString());
    }
  }, []);

  useEffect(() => {
    if (contextProductOptions) setProductOptions(contextProductOptions);
  }, [contextProductOptions]);

  useEffect(() => {
    if (contextProducts) setProducts(contextProducts);
  }, [contextProducts]);

  const productChangedHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const productId = event.target.value;
    setSelectedProductOption(productId);
    setSelectedProductOptionError(false);
  };

  async function getEngagementSettings(uniqueName: string) {
    if (!uniqueName) {
      return;
    }
    const track = uniqueName.split('|')[0];
    const url = EngagementEventSetting.GET.replace('{uniqueName}', uniqueName).replace('{track}', track);
    const getProductsResult = await getEngagementEventSetting({
      url: url,
      method: 'GET',
    });
    resetFields();
    if (getProductsResult && getProductsResult.isSuccess && getProductsResult.result) {
      const engagementEvent: IEngagementEventSetting = getProductsResult.result;
      setSelectedProductOption(engagementEvent.subscriptionId);
      setSelectedScheduleDayOption(engagementEvent.schedule.dayNumber.toString());
      setSelectedScheduleOption(engagementEvent.schedule.timeToSend.toString());
      setEmailTemplateName(engagementEvent.sendViaEmail?.emailContent?.template ?? '');
      setNotificationTitle(engagementEvent.sendViaPush?.pushNotificationContent?.title ?? '');
      setNotificationDescription(engagementEvent.sendViaPush?.pushNotificationContent?.contentDescription ?? '');
      setSelectedNotificationActionOption(engagementEvent.sendViaPush?.pushNotificationContent?.action ?? NotificationActions.None.toString());
      setNotificationLink(engagementEvent.sendViaPush?.pushNotificationContent?.url ?? '');
    }
  }

  const scheduleChangedHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const schedule = event.target.value;
    setSelectedScheduleOption(schedule);
  };

  const scheduleDayChangedHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const schedule = event.target.value;
    setSelectedScheduleDayOption(schedule);
  };

  const selectedNotificationActionChangedHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const action = event.target.value;
    setSelectedNotificationActionOption(action);
  };

  const onClickDeleteHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    const deleteSettingResult = await deleteSetting({
      url: EngagementEventSetting.REMOVE,
      method: 'POST',
      data: {
        trackAndDays: selectedProductOption,
        uniqueName: uniqueName,
      },
    });
    showMessage('Communication details deleted successfully');
    resetFields();
  };

  const onClickSaveHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!selectedProductOption) {
      setSelectedProductOptionError(true);
      return;
    }

    if (selectedProductOption) {
      const engagementEvent: IEngagementEventSetting = {
        uniqueName: undefined,
        communicationType: 'GeneralEngagement',
        locale: 'en',
        subscriptionId: selectedProductOption,
        trackAndDays: selectedProductOption,
        status: 'Active',
        schedule: {
          dayNumber: parseInt(selectedScheduleDayOption),
          timeToSend: selectedScheduleOption,
        },
        sendViaEmail: {
          active: emailTemplateName ? true : false,
          emailContent: {
            template: emailTemplateName,
          },
        },
        sendViaPush: {
          active: notificationTitle ? true : false,
          pushNotificationContent: {
            url: notificationLink,
            name: notificationTitle,
            action: selectedNotificationActionOption,
            contentDescription: notificationDescription,
            title: notificationTitle,
          },
        },
      };
      const saveSettingResult = await saveSetting({
        url: EngagementEventSetting.POST,
        method: 'POST',
        data: engagementEvent,
      });
      showMessage('Communication details saved successfully');
    }
  };

  const showMessage = (message: string) => {
    setTimeout(() => setMessage(''), 5000);
    setMessage(message);
  };
  const resetFields = () => {
    setSelectedProductOption('');
    setSelectedScheduleDayOption('1');
    setSelectedScheduleOption('0');
    setEmailTemplateName('');
    setNotificationTitle('');
    setNotificationDescription('');

    setSelectedNotificationActionOption('');
    setNotificationLink('');
  };
  return (
    <>
      {currentUserInfo && currentUserInfo.isHealthCoach && (
        <Base>
          <StyledLayout>
            <PageHeader title='Communication Details'></PageHeader>
            <Flex
              flexDirection='column'
              layout='fill-space'
              alignItems='initial'
              justifyContent='initial'
              flexWrap='nowrap'
              style={{ marginLeft: 120, marginTop: 18, height: 'auto', width: 600 }}
            >
              {products && (
                <>
                  <FormField
                    field={Select}
                    label={'Product'}
                    onChange={productChangedHandler}
                    value={selectedProductOption}
                    options={productOptions}
                    fieldProps={{ className: 'up-select' }}
                    layout='horizontal'
                    errorText='Please select a product'
                    error={selectedProductOptionError}
                  />
                  <Flex style={{ marginTop: 16, gap: 8 }}>
                    <Heading level={6}>Schedule</Heading>
                    <Grid gridTemplateColumns='6fr 6fr' gridGap='16px'>
                      <FormField
                        field={Select}
                        label={'Day'}
                        onChange={scheduleDayChangedHandler}
                        value={selectedScheduleDayOption}
                        options={scheduleDayOptions}
                        fieldProps={{ className: 'up-select' }}
                        layout='horizontal'
                      />
                      <FormField
                        field={Select}
                        label={'Hour'}
                        onChange={scheduleChangedHandler}
                        value={selectedScheduleOption}
                        options={scheduleOptions}
                        fieldProps={{ className: 'up-select' }}
                        layout='horizontal'
                      />
                    </Grid>
                  </Flex>
                  <Flex style={{ marginTop: 16 }}>
                    <Heading level={6}>Email</Heading>
                    <Flex layout='equal-columns'>
                      <FormField
                        field={Input}
                        label={'Template Name'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailTemplateName(e.target.value)}
                        value={emailTemplateName}
                        layout='horizontal'
                        fieldProps={{ name: 'emailTemplateName', placeholder: 'Template Name' }}
                      />
                    </Flex>
                  </Flex>

                  <Flex style={{ marginTop: 16 }}>
                    <Heading level={6}>Push Notification</Heading>
                    <Flex>
                      <FormField
                        field={Input}
                        label={'Title'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotificationTitle(e.target.value)}
                        value={notificationTitle}
                        layout='horizontal'
                        fieldProps={{ name: 'notificationTitle', placeholder: 'Title', maxLength: 75 }}
                      />
                      <FormField
                        field={Textarea}
                        label={'Description'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotificationDescription(e.target.value)}
                        value={notificationDescription}
                        layout='horizontal'
                        fieldProps={{ name: 'notificationDescription', placeholder: 'Description', maxLength: 255 }}
                      />
                    </Flex>
                    <FormField
                      field={Select}
                      label={'Action'}
                      onChange={selectedNotificationActionChangedHandler}
                      value={selectedNotificationActionOption}
                      options={notificationActionOptions}
                      fieldProps={{ className: 'up-select' }}
                      layout='horizontal'
                    />
                    <FormField
                      field={Input}
                      label={'Url / Area'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotificationLink(e.target.value)}
                      value={notificationLink}
                      layout='horizontal'
                      fieldProps={{ name: 'notificationLink', placeholder: 'https://virtual.universalpractice.com.au or Messenger', maxLength: 50 }}
                    />
                  </Flex>
                  <Flex style={{ textAlign: 'center', marginTop: 8 }}>
                    <Label style={{ fontStyle: 'italic' }}>{message}</Label>
                  </Flex>
                  <Grid gridTemplateColumns='6fr 6fr' gridGap='32px' marginTop={32}>
                    <UpButton
                      className='delete-button'
                      type='button'
                      style={{ height: 42 }}
                      onClick={(e) => {
                        onClickDeleteHandler(e);
                      }}
                      isLoading={deleteSettingLoading}
                    >
                      <Label>Delete</Label>
                    </UpButton>
                    <UpButton
                      className='submit-button'
                      type='button'
                      style={{ height: 42 }}
                      onClick={(e) => {
                        onClickSaveHandler(e);
                      }}
                      isLoading={saveSettingLoading}
                    >
                      <Label>Save</Label>
                    </UpButton>
                  </Grid>
                  <Flex layout='equal-columns' justifyContent='space-between' style={{ marginTop: 24 }}></Flex>
                </>
              )}
            </Flex>
          </StyledLayout>
        </Base>
      )}
    </>
  );
};

export default CommunicationDetails;
