import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  border-bottom: ${({ theme }) => `0.03125rem solid ${theme.navbar.headerBorder}`};
  padding: 1rem;
  font-family: Moderat;
  .ch-title {
    flex: 1;
  }

  .ch-btn-close {
    margin-left: auto;
    margin-right: 1rem;
  }
`;
