import { ChangeEvent, FC } from 'react';
import { CSSProperties } from 'styled-components';
import { StyledLabel, StyledInput, StyledSwitch } from './Styled';

interface UPSwitchProps {
  children?: React.ReactChildren | React.ReactNode | string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  wrapperStyles: CSSProperties;
}

const UPSwitch: FC<UPSwitchProps> = ({ children, onChange, checked, wrapperStyles }) => {
  return (
    <StyledLabel style={wrapperStyles}>
      <StyledInput checked={checked || false} type='checkbox' onChange={onChange} />
      <StyledSwitch />
      <div
        title={typeof children === 'string' ? (children as string) : undefined}
        style={{ width: 'calc(100% - 50px)', textOverflow: typeof children === 'string' ? 'ellipsis' : 'unset', overflow: 'hidden' }}
      >
        {children}
      </div>
    </StyledLabel>
  );
};

export default UPSwitch;
