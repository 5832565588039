/* eslint-disable @typescript-eslint/no-explicit-any */
import { STORAGE } from '../constants';
import { Obj } from '../types';

export const EmailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export function clone<T = any>(data: Obj | Obj[]) {
  return <T>JSON.parse(JSON.stringify(data));
}

export const setLocalStorage = (key: string, data: Obj | Obj[] | string | number) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export function getLocalStorage<T = unknown>(key: string): T | undefined {
  const data = sessionStorage.getItem(key);
  if (!data) {
    return;
  }

  return JSON.parse(data);
}

export const removeLocalStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const clearAppointmentStorage = () => {
  removeLocalStorage(STORAGE.SAVE_PATIENT_TREATMENTS);
  removeLocalStorage(STORAGE.PATIENT_TREATMENT_NOTE_FORM_ID);
  removeLocalStorage(STORAGE.USER_PROGRAM);
  removeLocalStorage(STORAGE.PRESET_PROGRAMS);
  removeLocalStorage(STORAGE.PROGRAM_SUBSCRIPTION_ID);
  removeLocalStorage(STORAGE.PATIENT_EXTERNAL_ID);
  removeLocalStorage(STORAGE.PATIENT_ID);
  removeLocalStorage(STORAGE.SEARCHED_PATIENT);
  removeLocalStorage(STORAGE.APPOINTMENT_ID);
};

export const clearPatientAppointmentStorage = () => {
  removeLocalStorage(STORAGE.APPOINTMENT_ID);
};
