import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';

import React, { createContext, FC, useContext, useState } from 'react';

interface Props extends BaseSdkProps {
  children: React.ReactNode;
}

const UPFormPreviewContext = createContext<{
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  formId: string;
  setFormId: React.Dispatch<React.SetStateAction<string>>;
  setFormToShow: (formId: string) => void;
}>({
  showForm: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowForm: () => {},
  formId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFormId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFormToShow: () => {},
});

export const UPFormPreviewProvider: FC<Props> = ({ children }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formId, setFormId] = useState<string>('');
  const setFormToShow = (formId: string) => {
    setFormId(formId);
    if (formId) setShowForm(true);
    else setShowForm(false);
  };

  return <UPFormPreviewContext.Provider value={{ showForm, setShowForm, formId, setFormId, setFormToShow }}>{children}</UPFormPreviewContext.Provider>;
};

export const useUPFormPreview = () => useContext(UPFormPreviewContext);
