import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledSwitch = styled.div`
  position: relative;
  width: 48px;
  height: 20px;
  background: #404040;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 35px;
    top: 50%;
    left: 0px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledSwitch} {
    background: #afaeae;

    &:before {
      transform: translate(24px, -50%);
    }
  }
`;
