import { FormField, Input, Select } from 'amazon-chime-sdk-component-library-react';
import { SelectOptions } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Select';
import { ChangeEvent, FC, useEffect, useState } from 'react';
interface UPDropDownProps {
  className?: string;
  fieldName: string;
  fieldValue: string;
  index: number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  readOnly: boolean;
  options: { displayText: string; value: string }[];
}
const UPDropDown: FC<UPDropDownProps> = ({ className, fieldName, fieldValue, index, onChange, readOnly, options }) => {
  const [selectOptions, setSelectOptions] = useState<SelectOptions[]>();
  const [value, setValue] = useState(fieldValue || '');

  useEffect(() => {
    if (options) {
      const selectOptions = options.map(
        (t) =>
          ({
            value: t.value,
            label: t.value,
          } as SelectOptions)
      );
      selectOptions.unshift({ value: '', label: '' });
      setSelectOptions(selectOptions);
    } else setSelectOptions([]);
  }, [options]);

  return (
    <>
      {readOnly && value && (
        <FormField
          field={Input}
          id={fieldName}
          value={value}
          label={fieldName}
          layout='horizontal'
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            return;
          }}
          fieldProps={{ readOnly: true }}
        ></FormField>
      )}
      {!readOnly && selectOptions && selectOptions.length > 0 && (
        <FormField
          field={Select}
          fieldProps={{ className: 'up-select' }}
          id={fieldName}
          value={value}
          label={fieldName}
          layout='horizontal'
          options={selectOptions}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            const value = (event.target as HTMLSelectElement).value;
            setValue(value);
            onChange(event);
          }}
        ></FormField>
      )}
    </>
  );
};

export default UPDropDown;
